import * as PIXI from 'pixi.js-legacy';
import RenderUtils from './render_utils';
import LevelManager, { SaveType } from "./levels";
import EventEmitter from "eventemitter3";

import * as IDPool from './utils/id-pool';
import { Logging } from './utils/logging';

import FlatWorld from './world';

import { BackgroundSavedState, CostumeSavedState, SavedState, SpriteSavedState, StartConfiguration } from "./level-state";
import { Restore, RestoreCostumeAddStatus } from "./utils/restore";
import { translate } from "./i18n/i18n";
import { MIPMAP_MODES } from "pixi.js-legacy";
import { AppRoot } from './app';

export class CostumeEntry {
    id: number;
    name: string;
    data: string;
    isSystemCostume = false;
    image: HTMLImageElement;
    texture: PIXI.Texture;
    offset: { x: number, y: number };

    static deserialize(savedState: SavedState, sprite: Sprite) {
        let costumeSavedState = savedState as CostumeSavedState;
        const costumeEntry = new CostumeEntry(costumeSavedState.name, costumeSavedState.data, costumeSavedState.id, sprite);
        costumeEntry.isSystemCostume = costumeSavedState.isSystemCostume;
        return costumeEntry;
    }

    constructor(name: string, data: string, id: number = -1, sprite: Sprite | null = null) {
        this.id = id;
        this.name = name;
        this.data = data;
        this.image = new Image();
        this.image.src = data;
        this.offset = {
            x: -1,
            y: -1
        };
        let options = {};
        if (sprite?.isStaticBackground) {
            options = {
                mipmap: MIPMAP_MODES.OFF
            };
        }
        this.texture = new PIXI.Texture(new PIXI.BaseTexture(this.image, options));
        if (sprite && !sprite.isStaticBackground) {
            this.cropCostume(data, sprite);
        }
    }

    cropCostume(data: string, sprite: Sprite) {
        let image = new Image();
        image.src = data;

        function rowBlank(imageData: ImageData, width: number, y: number) {
            for (let x = 0; x < width; ++x) {
                if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) {
                    return false;
                }
            }
            return true;
        }

        function columnBlank(imageData: ImageData, width: number, x: number, top: number, bottom: number) {
            for (let y = top; y < bottom; ++y) {
                if (imageData.data[y * width * 4 + x * 4 + 3] !== 0) {
                    return false;
                }
            }
            return true;
        }

        image.onload = () => {
            let c = document.createElement("canvas");
            c.width = image.width;
            c.height = image.height;
            let ctx = c.getContext("2d");
            if (ctx) {
                ctx.drawImage(image, 0, 0);
                let width = c.width;
                let imageData = ctx.getImageData(0, 0, c.width, c.height);
                let top = 0, bottom = imageData.height, left = 0, right = imageData.width;

                while (top < bottom && rowBlank(imageData, width, top)) {
                    ++top;
                }
                while (bottom - 1 > top && rowBlank(imageData, width, bottom - 1)) {
                    --bottom;
                }
                while (left < right && columnBlank(imageData, width, left, top, bottom)) {
                    ++left;
                }
                while (right - 1 > left && columnBlank(imageData, width, right - 1, top, bottom)) {
                    --right;
                }

                let trimmed = ctx.getImageData(left, top, right - left, bottom - top);
                let copy = c.ownerDocument.createElement("canvas");
                let copyCtx = copy.getContext("2d");
                if (copyCtx) {
                    copy.width = trimmed.width;
                    copy.height = trimmed.height;
                    this.offset = {
                        x: Math.round((copy.width - trimmed.width) / 2),
                        y: Math.round((copy.height - trimmed.height) / 2)
                    };
                    copyCtx.putImageData(trimmed, this.offset.x, this.offset.y);

                    this.image.onload = () => {
                        this.texture = new PIXI.Texture(new PIXI.BaseTexture(this.image));
                        sprite.updateCostume();
                    }

                    this.image.src = copyCtx.canvas.toDataURL("image/png");
                }
            }
        };
    }

    serialize(): CostumeSavedState {
        Logging.silly(`Serializing costume with ID:${this.id}, NAME:${this.name}`);
        return {
            id: this.id,
            name: this.name,
            data: this.image.src,
            isSystemCostume: this.isSystemCostume
        };
    }

    toString(): string {
        return `Costume[${this.id}|'${this.name}']`;
    }
}

enum BubbleDirection {
    Right,
    Left
}

interface CachedTouchEvaluations {
    edge: boolean,
    sprite: boolean
}

/**
 * Wrapper around PIXI-Sprite which provides convenience functions.
 *
 * @param {String} name Unique identifier of the sprite.
 *
 * @property {String} name Unique identifier of the sprite.
 * @property {CostumeEntry[]} costumes List of all costumes available to the sprite.
 * @property {number} draggingSnapValue Snaps the sprite to a "grid" when dragging with the mouse.
 * @property {number} costumeId ID of the current costume in {@link Sprite.costumes}.
 * @property {boolean} visible Whether the sprite should be drawn.
 * @property {number} rotation The sprites rotation in degrees.
 * @property {number} scale A multiplier for the current texture size.
 * @property {number} orientation The mirrored states (based on scale, not axis) of the sprite 0 = none, 1 = y, 10 = x, 11 = x and y
 * @property {Object.<string, number>} position A dict containing the current position. { x: xValue, y: yValue}
 * @property {boolean} draggable Whether the sprite can be dragged with click and hold.
 * @property {boolean} programmable Whether the sprite can be programmed in student mode.
 * @property {PIXI.Container} textContainer Container for all text objects.
 * @property {PIXI.Sprite} sprite The underlying PIXI Sprite.
 *
 * @constructor
 */

export default class Sprite {

    static eventEmitter: EventEmitter;
    static renderUtils: RenderUtils;
    static maxClones: number = 64;

    DEFAULT_SPEED = 400;

    id: number;
    guid: string;
    name: string;
    costumes: CostumeEntry[];
    allowCostumeEdit: boolean;
    draggable: boolean;
    programmable: boolean;
    deletable: boolean;
    mobileOnly: boolean;
    isInput: boolean;
    lastPosition : number[];
    cachedTouchEvaluations: CachedTouchEvaluations;
    textContainer: PIXI.Container;
    textDirection: BubbleDirection;
    pixiSprite: PIXI.Sprite;
    costumeId: number;
    textStyle: PIXI.TextStyle;
    dragging: boolean;
    holding: boolean
    isStaticBackground: boolean;
    data: any;
    dragPoint: PIXI.Point;
    onClick: Function;
    onPositionChanged: Function;
    speed: number;
    mirrored: boolean;
    startConfiguration: StartConfiguration;

    worldContainer: PIXI.Container | null;
    parent: Sprite | null;
    clones: Sprite[];
    monitoredSprites: number[];
    touchingSpriteBLOCKED: number;

    static deserialize(savedState: SavedState, backgroundCenter: PIXI.Point | null = null) {
        let spriteSavedState = savedState as SpriteSavedState;

        let sprite = new Sprite(spriteSavedState.name, spriteSavedState.id);
        sprite.x = spriteSavedState.position.x;
        sprite.y = spriteSavedState.position.y;
        sprite.rotation = spriteSavedState.rotation;
        sprite.scale = spriteSavedState.scale;
        sprite.mirrored = spriteSavedState.mirrored;
        sprite.orientation = spriteSavedState.orientation;
        sprite.allowCostumeEdit = spriteSavedState.allowCostumeEdit;
        sprite.programmable = spriteSavedState.programmable;
        sprite.deletable = spriteSavedState.deletable;
        sprite.draggable = spriteSavedState.draggable;
        sprite.mobileOnly = spriteSavedState.mobileOnly;
        sprite.isInput = spriteSavedState.isInput;
        sprite.visible = spriteSavedState.visible;
        sprite.layer = spriteSavedState.layer;
        sprite.speed = sprite.DEFAULT_SPEED;
        sprite.costumeId = spriteSavedState.costumeId;
        sprite.costumes = [];
        sprite.startConfiguration = spriteSavedState.startConfiguration;
        if (backgroundCenter) {
            sprite.makeBackground(backgroundCenter);
        }
        spriteSavedState.costumes.forEach((costumeSavedState) => {
            sprite.addCostume(CostumeEntry.deserialize(costumeSavedState, sprite));
        });
        return sprite;
    }

    static borderLimit() {
        return 20;
    }

    static getAllSprites() : Sprite[] {
        const world = (window as any).world as FlatWorld;
        const sprites = world.sprites.filter(s => !s.isStaticBackground);
        return sprites.concat(([] as Sprite[]).concat.apply([], sprites.map(s => s.clones)));
    }

    constructor(name: string, id: number = -1, backgroundCenter: PIXI.Point | null = null) {

        this.monitoredSprites = [];

        this.onPositionChanged = () => {
        };
        this.onClick = () => null;

        this.clones = [];
        this.parent = null;
        this.allowCostumeEdit = true;
        this.dragging = false;
        this.holding = false;
        this.dragPoint = new PIXI.Point();
        this.speed = this.DEFAULT_SPEED;

        this.worldContainer = null;

        this.id = id;
        this.guid = Date.now().toString(16) + Math.random().toString(16).substr(2);
        this.name = name;
        this.costumes = [];
        this.costumeId = -1;
        this.draggable = true;
        this.mobileOnly = false;
        this.isInput = false;
        this.programmable = true;
        this.deletable = false;
        this.isStaticBackground = false;
        this.textContainer = new PIXI.Container();
        this.textDirection = BubbleDirection.Right;

        this.pixiSprite = new PIXI.Sprite();
        this.pixiSprite.anchor.set(0.5);

        this.pixiSprite.interactive = true;
        this.pixiSprite.on('mousedown', this._onPointerDown, this)
            .on('touchstart', this._onPointerDown, this)
            .on('mouseup', this._onPointerUp, this)
            .on('mouseupoutside', this._onPointerUp, this)
            .on('touchend', this._onPointerUp, this)
            .on('touchendoutside', this._onPointerUp, this)
            .on('mousemove', this._onPointerMove, this)
            .on('touchmove', this._onPointerMove, this);

        this.textStyle = new PIXI.TextStyle({
            fontSize: 14,
            wordWrap: true,
            wordWrapWidth: 150,
        });

        this.x = 0;
        this.y = 0;
        this.lastPosition = [Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
        this.cachedTouchEvaluations = {
            edge: false,
            sprite: false,
        };
        this.scale = 1;
        this.rotation = 90;
        this.mirrored = false;
        this.orientation = 0;
        this.visible = true;
        this.layer = 1;

        this.startConfiguration = {
            position: {
                x: this.x,
                y: this.y
            },
            scale: this.scale,
            rotation: this.rotation,
            mirrored: this.mirrored,
            orientation: this.orientation,
            visible: this.visible,
            layer: this.layer
        } as StartConfiguration;

        this.touchingSpriteBLOCKED = 0;

        if (backgroundCenter) {
            this.makeBackground(backgroundCenter);
        }
    }

    setX(val: number) {
        this.x = val;
        this.onPositionChanged(this.x, this.y);
    }

    setY(val: number) {
        this.y = val;
        this.onPositionChanged(this.x, this.y);
    }

    getInstances() {
        return [this as Sprite].concat(this.clones);
    }

    clone(): Sprite | null {
        if (this.isStaticBackground) {
            throw new Error("Cannot clone static sprites");
        }
        //this.logger.debug(`Cloning ${this}.`);

        let root: Sprite = this;
        if (root.parent) {
            root = root.parent;
        }

        if (root.clones.length >= Sprite.maxClones) {
            return null;
        }

        let clone = new Sprite(this.name, this.id);
        clone.parent = root;
        root.clones.push(clone);

        clone.onClick = this.onClick;

        clone.costumeId = this.costumeId;
        this.costumes.forEach((entry) => {
            clone.addCostume(entry);
        });

        clone.visible = this.visible;
        clone.rotation = this.rotation;
        clone.scale = this.scale;
        clone.mirrored = this.mirrored;
        clone.orientation = this.orientation;
        clone.x = this.x;
        clone.y = this.y;

        clone.updateCostume();
        clone.setWorldContainer(root.worldContainer);
        return clone;
    }

    removeClone(clone: Sprite) {
        this.clones = this.clones.filter((spr) => spr !== clone);
        clone.setWorldContainer(null);
    }

    removeAllClones() {
        this.clones.forEach((clone) => clone.setWorldContainer(null));
        this.clones = [];
    }

    setWorldContainer(container: PIXI.Container | null) {
        if (this.worldContainer) {
            this.worldContainer.removeChild(this.pixiSprite);
            this.worldContainer.removeChild(this.textContainer);
        }
        this.worldContainer = container;
        if (this.worldContainer) {
            if (this.isStaticBackground) {
                this.worldContainer.addChildAt(this.pixiSprite, 0);
            } else {
                this.worldContainer.addChild(this.pixiSprite, this.textContainer);
            }
        }
    }

    makeBackground(center: PIXI.Point) {
        this.visible = true;
        this.rotation = 90;
        this.scale = 1;
        this.x = center.x;
        this.y = center.y;

        this.draggable = false;
        this.isStaticBackground = true;
    }

    adjustBackground() {
        const stageRatio = FlatWorld.worldSize().width / FlatWorld.worldSize().height;

        const tex = this.pixiSprite.texture;
        const texRatio = tex.width / tex.height;

        let scale = 1;
        if (stageRatio < texRatio) {
            scale = FlatWorld.worldSize().width / tex.width;
        } else {
            scale = FlatWorld.worldSize().height / tex.height;
        }

        this.pixiSprite.scale.x = parseFloat(scale.toFixed(2));
        this.pixiSprite.scale.y = parseFloat(scale.toFixed(2));
    }

    addCostume(costume: CostumeEntry) {
        //this.logger.debug(`Adding costume ${costume.name} to ${this}.`);
        if (costume.id === -1) {
            costume.id = IDPool.getFreeId(this.costumes);
        }
        costume.name = IDPool.getFreeName(this.costumes, costume.name);
        this.costumes.push(costume);
        if (this.costumeId === -1) {
            this.costumeId = costume.id;
            this.updateCostume();
        }

        // TODO: ugly!
        const selectedSprite = (window as any).getSelectedSprite() as Sprite;
        if (selectedSprite === this) {
            // update the sprite editor
            (window as any).setSelectedSprite(this);
        }
    }

    removeCostume(costume: CostumeEntry) {
        this.removeCostumeById(costume.id);
    }

    removeCostumeById(id: number) {
        const index = this.costumes.findIndex((c) => c.id === id);
        if (index !== -1) {
            if (this.costumeId === id) {
                this.previousCostume();
            }
            //this.logger.debug(`Removing costume ${this.costumes[index].name} to ${this}.`);
            this.costumes.splice(index, 1);
        }
    }

    addCostumeBlob(name: string, data: Blob) {
        if (!data.type.startsWith('image/')) {
            Logging.error('addCostumeBlob: no image file');
            return;
        }

        let r = new FileReader();
        r.readAsDataURL(data);
        r.onload = () => {
            this.addCostume(new CostumeEntry(name, r.result as string, -1, this));
            switch (Restore.costumeAddStatus) {
                case RestoreCostumeAddStatus.COSTUME:
                    Restore.createRestorePoint(translate('RestoreMessages.costume.added') + name);
                    Restore.costumeAddStatus = RestoreCostumeAddStatus.NONE;
                    break;
                case RestoreCostumeAddStatus.SPRITE:
                    Restore.createRestorePoint(translate('RestoreMessages.sprite.added') + this.name);
                    Restore.costumeAddStatus = RestoreCostumeAddStatus.NONE;
                    break;
                case RestoreCostumeAddStatus.RESET:
                    if (Restore.blockedReset) {
                        Restore.blockedReset = false;
                    } else {
                        Restore.reset((window as any).levelManager.save(SaveType.OBJECT));
                        Restore.costumeAddStatus = RestoreCostumeAddStatus.NONE;
                    }
                    break;
                default:
                    break;
            }
        };
    }

    addCostumeUrl(name: string, url: string) {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => this.addCostumeBlob(name, blob))
            .catch((err) => Logging.error("Failed to load costume:" + err.toString()));
    }

    getStartScale() {
        return this.startConfiguration.scale;
    }

    get visible() {
        return this.pixiSprite.visible;
    }

    set visible(value: boolean) {
        if (this.isStaticBackground) {
            throw new Error("Cannot change visibility of static sprites");
        }
        this.pixiSprite.visible = value;
    }

    get rotation() {
        return this.pixiSprite.rotation * 180 / Math.PI + 90;
    }

    set rotation(value: number) {
        if (this.isStaticBackground) {
            throw new Error("Cannot change rotation of static sprites");
        }
        this.pixiSprite.rotation = (value - 90) * Math.PI / 180;
    }

    get scale() {
        return Math.abs(this.pixiSprite.scale.x * 2);
    }

    set scale(value: number) {
        if (this.isStaticBackground) {
            throw new Error("Cannot change scale of static sprites");
        }

        value = Math.min(value, 10);
        value = Math.max(value, 0.1);
        const mirroredX = this.pixiSprite.scale.x < 0 ? -1 : 1;
        const mirroredY = this.pixiSprite.scale.y < 0 ? -1 : 1;
        this.pixiSprite.scale.x = value * 0.5 * mirroredX;
        this.pixiSprite.scale.y = value * 0.5 * mirroredY;
    }

    get orientation() {
        const mirroredX = this.pixiSprite.scale.x < 0 ? 1 : 0;
        const mirroredY = this.pixiSprite.scale.y < 0 ? 1 : 0;
        return mirroredX * 10 + mirroredY;
    }

    set orientation(value: number) {
        const mirroredX = Math.floor((value / 10) % 10) === 0 ? 1 : -1;
        const mirroredY = value % 10 === 0 ? 1 : -1;
        this.pixiSprite.scale.x = Math.abs(this.pixiSprite.scale.x) * mirroredX;
        this.pixiSprite.scale.y = Math.abs(this.pixiSprite.scale.y) * mirroredY;
        if (mirroredX === -1 ? mirroredY > 0 : mirroredY < 0) {
            if (!this.mirrored) {
                this.rotation = (this.rotation * -1 + 360) % 360;
                this.mirrored = !this.mirrored;
            }
        } else {
            if (this.mirrored) {
                this.rotation = (this.rotation * -1 + 360) % 360;
                this.mirrored = !this.mirrored;
            }
        }
    }

    get x() {
        return this.pixiSprite.x;
    }

    set x(value: number) {
        if (this.isStaticBackground) {
            throw new Error("Cannot change position of static sprites");
        }
        let renderUtils = (window as any).renderUtils as RenderUtils;
        const bounds = renderUtils.getBounds(this);
        const stageBounds = renderUtils.getStageBounds();

        value = Math.max(value, stageBounds.left - bounds.width / 2 + Sprite.borderLimit());
        value = Math.min(value, stageBounds.right + bounds.width / 2 - Sprite.borderLimit());
        this.pixiSprite.position.x = value;
        this.updateTextBubble(bounds, stageBounds);
        this.onPositionChanged(this.x, this.y);
    }

    get y() {
        return -this.pixiSprite.y;
    }

    set y(value: number) {
        if (this.isStaticBackground) {
            throw new Error("Cannot change position of static sprites");
        }
        let renderUtils = (window as any).renderUtils as RenderUtils;
        const bounds = renderUtils.getBounds(this);
        const stageBounds = renderUtils.getStageBounds();

        value *= -1;
        value = Math.max(value, stageBounds.top - bounds.height / 2 + Sprite.borderLimit());
        value = Math.min(value, stageBounds.bottom + bounds.height / 2 - Sprite.borderLimit());
        this.pixiSprite.position.y = value;
        this.updateTextBubble(bounds, stageBounds);
        this.onPositionChanged(this.x, this.y);
    }

    get layer() {
        if (this.worldContainer) {
            return this.worldContainer.getChildIndex(this.pixiSprite);
        } else {
            return 1;
        }
    }

    set layer(index: number) {
        if (this.worldContainer && !this.isStaticBackground) {
            const oldIndex = this.layer;
            index = Math.max(index, 1);
            index = Math.min(index, this.worldContainer.children.length - 2);
            if (oldIndex === index) return;
            if (oldIndex < index) {
                this.worldContainer.setChildIndex(this.textContainer, index + 1);
                this.worldContainer.setChildIndex(this.pixiSprite, index);
            } else {
                this.worldContainer.setChildIndex(this.pixiSprite, index);
                this.worldContainer.setChildIndex(this.textContainer, index + 1);      
            }
        }
    }

    //call this ordered from lowest to highest index
    resetLayer() {
        if (this.worldContainer && !this.isStaticBackground) {
            if (this.startConfiguration.layer === -1) {
                this.startConfiguration.layer = this.layer;
            } else {
                const maxIndex = this.worldContainer.children.length -2;
                if (this.startConfiguration.layer > maxIndex) {
                    this.startConfiguration.layer = maxIndex;
                }
                this.worldContainer.setChildIndex(this.pixiSprite, this.startConfiguration.layer);
                this.worldContainer.setChildIndex(this.textContainer, this.startConfiguration.layer + 1);  
            }
        }
    }

    hasNewPosition() {
        return this.lastPosition[0] !== this.x || this.lastPosition[1] !== this.y;
    }

    moveToTop() {
        if (this.worldContainer) {
            this.layer = this.worldContainer.children.length - 2;
        }
    }

    moveBack(layers: number) {
        this.layer = this.layer - layers * 2;
    }

    mirror(axis: string) {
        const mirroredX = Math.floor((this.orientation / 10) % 10) === 0 ? 0 : 1;
        const mirroredY = this.orientation % 10 === 0 ? 0 : 1;
        if (axis === "X") {
            this.orientation = (1 - mirroredX) * 10 + mirroredY;
        } else {
            this.orientation = mirroredX * 10 + 1 - mirroredY;
        }
    }

    updateCostume() {
        const costume = this.costumes.find((c) => c.id === this.costumeId);
        if (costume) {
            //this.logger.debug(`Setting costume on ${this} to ${costume}`);
            this.pixiSprite.texture = costume.texture;

            // auto scale the background
            if (this.isStaticBackground) {
                if (this.pixiSprite.texture.baseTexture.valid) {
                    this.adjustBackground();
                } else {
                    this.pixiSprite.texture.baseTexture.once("loaded", this.adjustBackground, this);
                }
            }
        }
    }

    nextCostume() {
        let index = this.costumes.findIndex((c) => c.id === this.costumeId);
        if (index === -1) {
            return;
        }
        index++;
        if (index === this.costumes.length) {
            index = 0;
        }
        //this.logger.debug(`Switching to next costume ${this.costumes[index].name} on ${this}.`);
        this.costumeId = this.costumes[index].id;
        this.updateCostume();
    }

    previousCostume() {
        let index = this.costumes.findIndex((c) => c.id === this.costumeId);
        if (index === -1) {
            return;
        }
        index--;
        if (index === -1) {
            index = this.costumes.length - 1;
        }
        //this.logger.debug(`Switching to previous costume ${this.costumes[index].name} on ${this}.`);
        this.costumeId = this.costumes[index].id;
        this.updateCostume();
    }

    updateTextBubble(bounds: PIXI.Rectangle, stageBounds: PIXI.Rectangle, perm: boolean = true) {
        if (this.textContainer.children.length === 0) {
            return;
        }
        if (perm) {
            const textElem = this.textContainer.getChildAt(1) as PIXI.Text;
            if (!textElem.visible) {
                return;
            }
        }
        if (!perm) {
            this.textDirection = BubbleDirection.Right;
        }

        if (this.textDirection === BubbleDirection.Right) {
            if (bounds.right + this.textContainer.width > stageBounds.right) { //overlaps?
                if (bounds.left - stageBounds.left > stageBounds.right - bounds.right) { //more space on left?
                    this.textDirection = BubbleDirection.Left;
                }
            }
        } else {
            if (bounds.left - this.textContainer.width < stageBounds.left) { //overlaps?
                if (stageBounds.right - bounds.right > bounds.left - stageBounds.left) { //more space on right?
                    this.textDirection = BubbleDirection.Right;
                }
            }
        }

        let bubble = this.textContainer.getChildAt(0);
        const textElem = this.textContainer.getChildAt(1) as PIXI.Text;

        if (this.textDirection === BubbleDirection.Right) {
            this.textContainer.position.x = Math.max(bounds.right, stageBounds.left);
            bubble.scale.x = 1;
            bubble.position.x = 0;
        } else {
            const bubbleWidth = Math.max(textElem.width, 40) + 24;
            this.textContainer.position.x = Math.min(bounds.left, stageBounds.right) - bubbleWidth;
            bubble.scale.x = -1;
            bubble.position.x = bubbleWidth;
        }
        this.textContainer.position.y = Math.min(Math.max(bounds.top, stageBounds.top + textElem.height + 30), stageBounds.bottom);
    }

    /**
     *  Initializes text component
     */
    initText() {
        const textElem = new PIXI.Text("Hallo", this.textStyle);
        textElem.x = 12;
        const graphics = new PIXI.Graphics();
        this.textContainer.addChild(graphics);
        this.textContainer.addChild(textElem);
    }

    /**
     * Hides the active text bubble.
     *
     * @public
     */
    clearText() {
        if (this.textContainer.children.length > 0) {
            const graphics = this.textContainer.getChildAt(0) as PIXI.Graphics;
            graphics.clear();
            const textElem = this.textContainer.getChildAt(1) as PIXI.Text;
            textElem.visible = false;
        }
    }

    /**
     * Adds a text bubble to the sprite.
     *
     * @param {String} text Text to be attached
     * @param {String} type Type of the text ("speech" or "thought")
     *
     * @param {boolean} perm true if text is permanent
     * @public
     */
    attachText(text: string, type: "speech" | "thought", perm: boolean = true) {
        if (this.textContainer.children.length === 0) {
            this.initText();
        }
        this.clearText();

        let renderUtils = (window as any).renderUtils as RenderUtils;
        const bounds = renderUtils.getBounds(this);
        const stageBounds = renderUtils.getStageBounds();
        this.updateTextBubble(bounds, stageBounds, perm);

        const textElem = this.textContainer.getChildAt(1) as PIXI.Text;
        textElem.text = text;
        textElem.visible = true;

        const fullHeight = textElem.height + 15 + 14;
        textElem.y = 7 - fullHeight;
        const width = Math.max(textElem.width, 40);

        const graphics = this.textContainer.getChildAt(0) as PIXI.Graphics;
        graphics.lineStyle(3, 0xA0A0A0);
        graphics.beginFill(0xFFFFFF);
        graphics.drawRoundedRect(0, -fullHeight, width + 24, textElem.height + 14, 10);
        graphics.endFill();

        if (type === "speech") {
            graphics.moveTo(22, -15);
            graphics.beginFill(0xFFFFFF);
            graphics.lineStyle(3, 0xFFFFFF);
            graphics.lineTo(22 + 30, -15);
            graphics.lineStyle(3, 0xA0A0A0);
            graphics.lineTo(15, 0);
            graphics.lineTo(22, -15);
            graphics.endFill();
        } else if (type === "thought") {
            graphics.lineStyle(1, 0xA0A0A0);
            graphics.beginFill(0xFFFFFF);
            graphics.drawEllipse(15, 0, 3, 2);
            graphics.lineStyle(2, 0xA0A0A0);
            graphics.drawEllipse(24, -4, 5, 2);
            graphics.drawEllipse(32, -10, 6, 3);
            graphics.endFill();
        }
        this.updateTextBubble(bounds, stageBounds, perm);
    }

    writeSpriteData(sprite?: PIXI.Sprite, skipTexture: boolean = false) {
        if (!sprite) {
            sprite = new PIXI.Sprite(this.pixiSprite.texture);
        }
        sprite.anchor.set(0.5);
        sprite.position.x = this.pixiSprite.position.x;
        sprite.position.y = this.pixiSprite.position.y;
        sprite.scale.x = this.pixiSprite.scale.x;
        sprite.scale.y = this.pixiSprite.scale.y;
        sprite.rotation = this.pixiSprite.rotation;
        sprite.visible = this.pixiSprite.visible;
        if (!skipTexture) {
            sprite.texture = this.pixiSprite.texture;
        }
        return sprite;
    }

    /**
     * Handles a mouse/touch down event.
     * Calls external event handler {@link Sprite.onClick()}.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerDown(event: PIXI.InteractionEvent) {
        const mousePos = event.data.getLocalPosition(this.pixiSprite.parent);
        mousePos.y *= -1;
        let renderUtils = (window as any).renderUtils as RenderUtils;
        if (renderUtils.isTouchingMouse(this, mousePos)) {
            if ((this.draggable || LevelManager.isBuildMode()) && !this.dragging && !this.isStaticBackground) {
                this.data = event.data;
                this.dragging = true;
                this.scale *= 1.1;
                this.dragPoint = mousePos;
                this.dragPoint.x -= this.x;
                this.dragPoint.y -= this.y;
            }
            if (!this.dragging && this.isInput && !this.holding) {
                this.holding = true;
                this.scale *= 1.1;
                this.moveToTop();
            }
            if((this.programmable || LevelManager.isBuildMode()) && !AppRoot.INSTANCE.state.playing) {
                // this is a cheat to make the clicked sprites workspace active
                this.onClick(this, false);
            }
        }
    }

    /**
     * Handles a mouse/touch up event.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerUp = (event: PIXI.InteractionEvent) => {
        if (this.dragging || this.holding) {
            this.dragging = false;
            this.holding = false;
            this.scale /= 1.1;
            this.data = null;
        }

        // Call click event
        this.onClick(this, true);
    };

    /**
     * Handles a mouse/touch move event.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerMove = (event: PIXI.InteractionEvent) => {
        if (this.dragging) {
            const newPosition = this.data.getLocalPosition(this.pixiSprite.parent);
            newPosition.y *= -1;
            this.x = newPosition.x - this.dragPoint.x;
            this.y = newPosition.y - this.dragPoint.y;
        }
    };

    serialize(): SpriteSavedState | BackgroundSavedState {
        Logging.debug(`Serializing ${this}.`);

        let spriteSavedState = {
            id: this.id,
            name: this.name,
            position: {
                x: this.x,
                y: this.y
            },
            scale: this.scale,
            rotation: this.rotation,
            mirrored: this.mirrored,
            orientation: this.orientation,
            draggable: this.draggable,
            programmable: this.programmable,
            deletable: this.deletable,
            mobileOnly: this.mobileOnly,
            isInput: this.isInput,
            visible: this.visible,
            layer: this.layer,
            allowCostumeEdit: this.allowCostumeEdit,
            costumeId: this.costumeId,
            costumes: this.costumes.map((costume) => costume.serialize()),
        };

        if (this.isStaticBackground) {
            return spriteSavedState as BackgroundSavedState;
        } else {
            spriteSavedState['startConfiguration'] = this.startConfiguration;
            return spriteSavedState as SpriteSavedState;
        }
    }

    setStartConfiguration() {
        this.startConfiguration = {
            position: {
                x: this.x,
                y: this.y
            },
            scale: this.scale,
            rotation: this.rotation,
            mirrored: this.mirrored,
            orientation: this.orientation,
            visible: this.visible,
            layer: this.layer
        } as StartConfiguration;
    }

    loadStartConfiguration() {
        if (this.startConfiguration) {
            this.setX(this.startConfiguration.position.x);
            this.setY(this.startConfiguration.position.y);
            this.scale = this.startConfiguration.scale;
            this.rotation = this.startConfiguration.rotation;
            this.mirrored = this.startConfiguration.mirrored;
            this.orientation = this.startConfiguration.orientation;
            this.visible = this.startConfiguration.visible;
            this.layer = this.startConfiguration.layer;
        }
    }

    toString(): String {
        return `Sprite[${this.id}|'${this.name}']`;
    }
}

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import './theme/cubi.css';
import { unregister } from './utils/registerServiceWorker';
import AppRoot from './app';
import i18n from './i18n/i18n';
import {I18nextProvider} from "react-i18next";
import {SnackbarProvider} from 'notistack';
import {createTheme, fade, ThemeProvider} from '@material-ui/core/styles';

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const primary = '#006EC2';
const secondary = '#B5E2FA';

unregister();

const theme = createTheme({
    palette: {
        primary: {
            main: primary,
        },
        secondary: {
            main: primary
        }
    },
    overrides: {
        MuiAppBar: {
            colorDefault: {
                backgroundColor: secondary
            }
        },
        MuiContainer: {
            root: {
                backgroundColor: secondary
            }
        },
        MuiTabs: {
            root: {
                backgroundColor: secondary
            }
        },
        MuiList: {
            root: {
                backgroundColor: secondary
            }
        },
        MuiCard: {
            root: {
                backgroundColor: fade('#FFF', 0.5)
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: secondary
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: secondary
            }
        },
        MuiPopover: {
            paper: {
                backgroundColor: secondary
            }
        },
        MuiIcon: {
            root: {
                color: primary
            }
        },
        MuiSvgIcon: {
            root: {
                color: primary
            }
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: 'transparent'
            }
        }
    }
});

ReactDOM.render(
    (
        <I18nextProvider i18n={i18n}>
            <SnackbarProvider maxSnack={5}>
                <ThemeProvider theme={theme}>
                    <AppRoot/>
                </ThemeProvider>
            </SnackbarProvider>
        </I18nextProvider>
    ), document.getElementById("mainContent"));

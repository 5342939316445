import * as React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Sprite from "../sprite";
import {WithTranslation, withTranslation} from 'react-i18next';
import {SlideUp} from "./transitions";
import {Restore, RestoreCostumeAddStatus} from "../utils/restore";
import Auxiliary from "src/utils/auxiliary";

interface UrlCostumeProps extends WithTranslation {
    readonly trigger: any;
    readonly sprite: Sprite;
    readonly parent: any;
}

interface UrlCostumeState {
    open: boolean;
    name: string;
    url: string;
}

class UrlCostumeModal extends React.Component<UrlCostumeProps, UrlCostumeState> {
    constructor(props: UrlCostumeProps) {
        super(props);
        this.state = {open: false, name: 'Kostüm', url: ''};
    }

    open = () => {
        this.setState({open: true});
        this.props.parent.handleCostumeMenuClose();
    };

    close = () => {
        this.setState({open: false});
    };

    upload = () => {
        Restore.costumeAddStatus = RestoreCostumeAddStatus.COSTUME;
        this.props.sprite.addCostumeUrl(this.state.name, this.state.url);
        this.setState({open: false, name: 'Kostüm', url: ''});
    };

    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({name: event.target.value});
    };

    handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({url: event.target.value});
    };

    handleKey = (event:any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.upload();
        }
    }

    render() {
        const {t} = this.props;
        return ([
            React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="form-dialog-title">{t('URLCostumeModal.title')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            id="costume-name"
                            label={t('URLCostumeModal.CostumeNameFieldLabel')}
                            value={this.state.name}
                            onChange={this.handleNameChange}
                            fullWidth={true}
                        />
                        <TextField
                            style={{marginTop: '10px'}}
                            autoFocus={true}
                            id="costume-url"
                            label={t('URLCostumeModal.URLFieldLabel')}
                            value={this.state.url}
                            onChange={this.handleUrlChange}
                            onKeyDown={this.handleKey}
                            fullWidth={true}
                            error={this.state.url !== "" && !Auxiliary.isValidUrl(this.state.url)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.upload} color="primary">
                            {t('Upload')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(UrlCostumeModal);

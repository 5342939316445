/*
* This module exports functions for the communication with aws,
* e.g. to store and load levels from cloud
*/

import environment from "src/environment";
import Swal from "sweetalert2";
import { translate} from '../i18n/i18n';
import LevelManager, {LoadingTypes} from "../levels";
import Storage from "../utils/storage";
import {AppRoot, notifyUser} from "../app";
/*
 * Get the aws api_endpoint
 */

const UPLOAD_API = {
    prod: "https://8q9av5o4od.execute-api.eu-central-1.amazonaws.com/uploads",
    dev: "https://9fauafqgt8.execute-api.eu-central-1.amazonaws.com/uploads"
}
const DOWNLOAD_API = {
    prod: "https://8q9av5o4od.execute-api.eu-central-1.amazonaws.com/downloads",
    dev: "https://9fauafqgt8.execute-api.eu-central-1.amazonaws.com/downloads"
}


/*
 * Interfaces for response
 */
interface UploadResponse{
    uploadURL: string;
    Key: string;
}
interface DownloadResponse{
    downloadURL: string;
    Key: string;
}

export interface CloudLevel {
    key: string,
    title: string,
    date: number
}

/*
 * Alert the error
 */
function errorAlert(message: string){
    Swal.fire({
        text: message,
        icon: 'error',
        confirmButtonText: "Ok",
        confirmButtonColor: '#FD5151',
        heightAuto: false
    })
}

/*
 * Receive an upload link
 */
async function getUploadLink(){

    try {
        let response = await fetch(UPLOAD_API[environment.ENV_NAME]);

        if(response.status !== 200){
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        let json:UploadResponse = await response.json();
        return json;

    }
    catch(e) {
        errorAlert(e.message);
        let res: UploadResponse= {uploadURL:"",Key:""};
        return res;
    }
}
/*
 * Receive a download link
 */
async function getDownloadLink(key:string){
    try {
        let response = await fetch(DOWNLOAD_API[environment.ENV_NAME] + "?file=" + key);

        if(response.status !== 200){
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        let json:DownloadResponse = await response.json();
        return json;

    }
    catch(e) {
        errorAlert(e.message);
        let res: DownloadResponse= {downloadURL:"",Key:""};
        return res;
    }
}

/*
 * Upload cubi file to cloud
 */
export async function uploadFileToCloud(file: string){
    const data = new Blob([file], { type: 'application/json' });
    const options = {
        method: 'PUT',
        headers: {},
        body: data,
    };
    try{
        const uploadInfo = await getUploadLink();
        if(uploadInfo.uploadURL === ""){
            throw new Error(translate("awsCloud.ErrorUpload"));
        }
        let response = await fetch(uploadInfo.uploadURL, options);
        if(response.status !== 200){
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        return uploadInfo.Key;
    }
    catch (e) {
        errorAlert(e.message);
        return "";
    }

}



/*
 * Download cubi file from cloud
 */
export async function downloadFileFromCloud(key:string){

    try{
        const downloadInfo = await getDownloadLink(key);
        if(downloadInfo.downloadURL === ""){
            throw new Error(translate("awsCloud.ErrorKey"));
        }
        let response = await fetch(downloadInfo.downloadURL);
        if(response.status !== 200){
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        let json:DownloadResponse = await response.json();
        return json;
    }
    catch (e) {
        errorAlert(e.message);
        return "";
    }
}

/*
* Name is the filename '' for levelTitle
*/

export function showDownload(name:string) {
    let levelManager = (window as any).levelManager as LevelManager;
    AppRoot.INSTANCE.resetProject();
    let file = levelManager.exportFile();
    notifyUser(translate('LevelDownloadModal.NOTIFY_SAVED'), { variant: 'success' });
    const blob = new Blob([file], { type: 'application/json;charset=UTF-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    if(name === ''){
        link.download = LevelManager.levelTitle + ".cubi";
    } else {
        link.download = name + ".cubi";

    }
    link.click()
    URL.revokeObjectURL(link.href)
}

/*
* Uplaod the current level to the Cloud and return the level code. 
*/
export async function saveLevelInCloud() {
    let levelManager = (window as any).levelManager as LevelManager;
    AppRoot.INSTANCE.resetProject();
    let file = levelManager.exportFile();
    const key = await uploadFileToCloud(file);
    if (key !== '') {
        let cloudLevels = Storage.get('CloudLevels', []) as CloudLevel[];
        cloudLevels.push({
            key: key,
            title: LevelManager.levelTitle,
            date: Date.now()
        })
        Storage.set('CloudLevels', cloudLevels);
    }
    return key;
}

/*
 * Load a level from a levelcode: String
*/
export async function loadLevelAws(key:string){
    return await downloadFileFromCloud(key)
        .then(res => {
            if(res === ""){
                return false;
            }
            let file = JSON.stringify(res)
            let levelManager = (window as any).levelManager as LevelManager;
            levelManager.importFile(file as string, { type: LoadingTypes.DIRECT_JSON });
            return true;
        });
}

/**
* Get static download link from key
*/
export function getStaticLink(key: string, directLink: boolean = false, embedded: boolean = false) {
   let urlOfLevel = "";
   let encodedURL = "";
   let linkOfLevel = "";
   let embeddedParam = embedded ? "&embedded=1" : "";
   if (environment.ENV_NAME === "prod") {
       urlOfLevel = `https://s3.eu-central-1.amazonaws.com/userlevels.i4k/${key}`;
       encodedURL = window.btoa(urlOfLevel);
       linkOfLevel = `https://editor.it-for-kids.org/?level=${encodedURL}${embeddedParam}`;
   }
   else if (environment.ENV_NAME === "dev") {
       urlOfLevel = `https://s3.eu-central-1.amazonaws.com/test.lea.i4k/${key}`;
       encodedURL = window.btoa(urlOfLevel);
       linkOfLevel = `https://editor.dev.it-for-kids.org/heads/development/index.html?level=${encodedURL}${embeddedParam}`;
   }
   if (directLink){
       return linkOfLevel;
   } else {
       return urlOfLevel;
   }
}
(window as any).getStaticLink = getStaticLink;

export interface CloudLevel {}
import * as React from 'react';
import {Typography} from '@material-ui/core';

const styles = {
    root: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

interface SettingsItemProps {
    readonly style?: any;
    readonly description: string;
    readonly title: string;
    children: any;
}

function SettingsItem(props: SettingsItemProps) {
    return (
        <div
            style={{
                ...styles.root,
                ...props.style
            }}
        >
            <div
                style={{
                    padding: '5px 10px 5px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 2
                }}
            >
                <Typography variant="body1">
                    {props.title}
                </Typography>
                <Typography variant="caption">
                    {props.description}
                </Typography>
            </div>
            <div
                style={{
                    padding: '5px 10px 5px 10px'
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default SettingsItem;

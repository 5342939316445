import * as PIXI from 'pixi.js-legacy';
import Sprite from './sprite';
import Monitor from './monitor';

import * as IDPool from './utils/id-pool';
import { Logging } from './utils/logging';

import RenderUtils from './render_utils';

export default class FlatWorld {
    container: PIXI.Container;
    monitorContainer: PIXI.Container;
    sprites: Sprite[];
    monitors: Monitor[];
    scale: number;
    centerAsOrigin: boolean;

    static worldSize() {
        return {width: 480, height: 360};
    }

    constructor(scale: number = 1) {
        this.container = new PIXI.Container();
        this.monitorContainer = new PIXI.Container();
        this.sprites = [];
        this.monitors = [];
        this.scale = scale;
        this.centerAsOrigin = false;
        this.initContainer(this.container);
        this.initContainer(this.monitorContainer);
        this.container.y += this.scale * FlatWorld.worldSize().height;
        this.updateOrigin(true);
    }

    private initContainer(container: PIXI.Container) {
        container.scale.x = this.scale;
        container.scale.y = this.scale;
    }

    updateOrigin(center: boolean) {
        if (this.centerAsOrigin !== center) {
            this.centerAsOrigin = center;
            let sign = center ? 1 : -1;
            this.container.x += sign * this.scale * FlatWorld.worldSize().width / 2;
            this.container.y -= sign * this.scale * FlatWorld.worldSize().height / 2;
            this.sprites.forEach((s) => {
                const staticSprite = s.isStaticBackground;
                s.isStaticBackground = false;
                s.x -= sign * FlatWorld.worldSize().width / 2;
                s.y -= sign * FlatWorld.worldSize().height / 2;
                s.isStaticBackground = staticSprite;
            });
        }
    }

    getCenter() {
        if (this.centerAsOrigin) {
            return new PIXI.Point(0, 0);
        } else {
            return new PIXI.Point(FlatWorld.worldSize().width / 2, FlatWorld.worldSize().height / 2);
        }
    }

    registerContainers(stage: PIXI.Container) {
        stage.addChild(this.container);
        stage.addChild(this.monitorContainer);
    }

    /**
     * Registers a sprite.
     * The sprite and its components are added to the active objects.
     *
     * @param {Sprite} sprite Sprite to add
     */
    registerSprite(sprite: Sprite) {
        Logging.debug(`Registered ${sprite} with ${this}.`);
        if (sprite.id === -1) {
            sprite.id = IDPool.getFreeArraySlot(this.sprites);
        }
        sprite.name = IDPool.getFreeName(this.sprites, sprite.name);
        this.sprites[sprite.id] = sprite;
        sprite.setWorldContainer(this.container);
    }

    /**
     * Removes the sprite with the given ID.
     * The sprite and its components are removed from the active objects.
     *
     * @param {number} id ID of the sprite to be removed
     */
    removeSpriteById(id: number) {
        this.sprites[id].getInstances().forEach((sprite) => {
            Logging.debug(`Removing sprite ${sprite} from ${this}.`);
            sprite.setWorldContainer(null);
        });
        delete this.sprites[id];
    }

    removeAllSprites() {
        Logging.debug(`Requesting to remove all sprites from ${this}.`);
        this.sprites.forEach((sprite) => this.removeSpriteById(sprite.id));
        this.sprites = [];
    }

    // TODO: this feels wrong here but it works for now
    resetSprites() {
        this.sprites.forEach((sprite) => {
            sprite.clearText();
            sprite.removeAllClones();
        });
    }

    registerMonitor(monitor: Monitor, autoPosition: boolean = false) {
        if (autoPosition) {
            const columnWidth = 100;
            const rowHeight = 32;

            monitor.x = 5;
            monitor.y = -5;

            let bounds = monitor.getBounds();

            while (this.monitors.find((m) => RenderUtils.rectIntersect(m.getBounds(), bounds))) {
                bounds.y += rowHeight;
                if (bounds.bottom > FlatWorld.worldSize().height) {
                    bounds.y = 5;
                    bounds.x += columnWidth;
                    if (bounds.right > FlatWorld.worldSize().width) {
                        bounds.x = 5;
                        break;
                    }
                }
            }

            monitor.x = bounds.x;
            monitor.y = -bounds.y;
        }
        this.monitors.push(monitor);
        this.monitorContainer.addChild(monitor.textContainer);
    }

    removeAllMonitors() {
        while (this.monitorContainer.children[0]) {
            this.monitorContainer.removeChild(this.monitorContainer.children[0]);
        }
        this.monitors = [];
    }

    getMonitorsById(id: string) {
        return this.monitors.filter((m) => m.id === id);
    }

    monitorEventDeleted(id: string) {
        const monitors = this.getMonitorsById(id);
        monitors.forEach((m) => this.monitorContainer.removeChild(m.textContainer));
        if (monitors.length > 0) {
            this.monitors = this.monitors.filter((m) => m.id !== id);
        }
    }

    monitorEventRename(id: string, newName: string) {
        const monitors = this.getMonitorsById(id);
        monitors.forEach((m) => m.setName(newName));
    }

    monitorEventValueChanged(id: string, newValue: string) {
        const monitors = this.getMonitorsById(id);
        monitors.forEach((m) => m.setValue(newValue));
    }

    setScale(scale: number) {
        this.scale = scale;
        this.initContainer(this.container);
    }

    toString(): string {
        return this.constructor.name;
    }

    setVisibility(value: number) {
        let alphaFilter = new PIXI.filters.AlphaFilter(value);
        this.sprites.forEach((sprite) => {
            if (sprite.isStaticBackground){
                if (value === 1) {
                    // @ts-ignore
                    sprite.pixiSprite.filters = null;
                } else {
                sprite.pixiSprite.filters = [alphaFilter]}
            }
        })
    }



}

// use this to define different environments and their corresponding properties
const envs = {
    dev: {
        fullLogging: true
    },
    prod: {
        fullLogging: false
    }
};

const name = (process.env as any).REACT_APP_STAGE === 'dev' ? 'dev' : 'prod';

// this is the global environment object with shared properties and expanded by the specific environment variables
let environment = {
    APP_NAME: "Cubi",
    ENV_NAME: name,
    CONTENT_FOLDER: './resources/content',
    PROXY_URL: 'https://my-cloud.link:3000/',
    ...envs[name]
};

export default environment;

export function changeEnvironment(env:string) {
    if (environment.ENV_NAME === env) {
        return;
    }
    environment.ENV_NAME = env;
    for (let [key, value] of Object.entries(envs[env])) {
        environment[key] = value;
    }
}

import {Logging} from "./utils/logging";
import EventEmitter from "eventemitter3";
import { AppRoot } from "./app";
import WorkspaceManager from "./workspace";

export default class Input {
    eventEmitter: EventEmitter;
    pressedKeys: number[];
    public static workspaceManager: WorkspaceManager;

    constructor(eventEmitter: EventEmitter) {
        this.eventEmitter = eventEmitter;
        this.pressedKeys = [];

        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
    }

    onKeyDown = (event: KeyboardEvent) => {
        if (Input.workspaceManager.api.sensing.getIgnoredKeyState(event.keyCode)) {
            return;
        }
        Logging.silly(`Registered KeyDown for ${event.key}`);
        const index = this.pressedKeys.indexOf(event.keyCode);
        if (index === -1) {
            this.pressedKeys.push(event.keyCode);
        }
        if (AppRoot.INSTANCE.state.playing) {
            this.eventEmitter.emit("key_pressed", event.keyCode);
        }
    };

    onKeyUp = (event: KeyboardEvent) => {
        if (Input.workspaceManager.api.sensing.getIgnoredKeyState(event.keyCode)) {
            return;
        }
        Logging.silly(`Registered KeyUp for ${event.key}`);
        const index = this.pressedKeys.indexOf(event.keyCode);
        if (index > -1) {
            this.pressedKeys.splice(index, 1);
        }
    };
}

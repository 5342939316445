import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button/Button';
import AnalyticsModal from './AnalyticsModal';
import SettingsModal from './SettingsModal';
import LevelLoadModal from './LevelLoadModal';
import ToolboxCreatorModal from './ToolboxCreatorModal';
import LevelDownloadModal from './LevelDownloadModal';
import LevelRestoreModal from "./LevelRestoreModal";
import LevelExportModal from "./LevelExportModal";
import IconButton from '@material-ui/core/IconButton/IconButton';
import BrushIcon from '@material-ui/icons/Brush';
import RemoveIcon from '@material-ui/icons/Remove';
import FolderIcon from '@material-ui/icons/Folder';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import TuneIcon from '@material-ui/icons/Tune';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import TranslateIcon from '@material-ui/icons/Translate';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Divider, Drawer, fade, FormControl, InputBase, List, ListItem, ListItemIcon, ListItemText, ListSubheader, makeStyles, MenuItem, Select, Toolbar } from '@material-ui/core';
import LevelManager from '../levels';
import { WithTranslation, withTranslation } from 'react-i18next';
import juniorprimologo from '../assets/img/junior-primo.png';
import i4klogo from '../assets/img/it-for-kids.png';

import mt from 'mousetrap';
import Swal from 'sweetalert2';
import { AppRoot } from '../app';
import PasswordModal from "./PasswordModal";

const useStyles = makeStyles(theme => ({
    logo: {
        height: 48,
        marginLeft: theme.spacing(2),
    },
    project: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.black, 0.25),
        transition: 'background-color 0.3s',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    projectWithHover: {
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.1)
        },
    },
    projectIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    inputInputReadOnly: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
        cursor: 'default',
    },
}));

function ProjectTitleInput(props: any) {
    const classes = useStyles();
    const { value, onChange, onMouseDown, readOnly } = props;

    return (
        <div className={`${classes.project} ${readOnly ? '' : classes.projectWithHover}`}>
            <div className={classes.projectIcon}>
                <FolderIcon />
            </div>
            <InputBase
                placeholder="Levelname"
                classes={{
                    root: classes.inputRoot,
                    input: readOnly ? classes.inputInputReadOnly : classes.inputInput,
                }}
                onChange={onChange}
                value={value}
                id={'lebel-name'}
                onMouseDown={onMouseDown}
                inputProps={{ 'aria-label': 'project' }}
                readOnly={readOnly}
            />
        </div>
    );
}

interface MenuAppBarProps extends WithTranslation {
    readonly playing: boolean;
    readonly runProject: () => void;
    readonly pauseProject: () => void;
    readonly resetProject: () => void;
    readonly newLevel: () => void;
    readonly newSprite: () => void;
    readonly duplicateSprite: () => void;
    readonly setAllStartPositions: () => void;
}

interface MenuAppBarState {
    open: boolean;
    levelTitle: string;
}

class MenuAppBar extends React.Component<MenuAppBarProps, MenuAppBarState> {
    constructor(props: MenuAppBarProps) {
        super(props);
        this.state = {
            open: false,
            levelTitle: LevelManager.levelTitle,
        };
    }

    handleClose = () => {
        this.setState({ open: false });
    };
    handleProjectMenu = (event: any) => {
        this.setState({ open: true });
    };

    handleLevelNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ levelTitle: event.target.value });
        LevelManager.levelTitle = event.target.value;
    };

    onMouseDown = (event: React.MouseEvent) => {
        if (AppRoot.INSTANCE.view === "embedded") {
            event.preventDefault();
        }
    };

    onChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { t } = this.props;
        let level = new URLSearchParams(window.location.search).get('level');
        const langKey = event.target.value as string;
        if (level === null) {
            level = '';
        } else {
            level = '&level=' + level;
        }
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?lang=' + langKey + level;
        if (AppRoot.INSTANCE.levelManager && AppRoot.INSTANCE.levelManager.downloadNecessary) {
            Swal.fire({
                text: t('Dialogs.ChangeLanguage'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Dialogs.ButtonYes'),
                cancelButtonText: t('Dialogs.ButtonNo'),
                confirmButtonColor: '#FD5151',
                cancelButtonColor: '#00A2E8',
                heightAuto: false
            }).then((result) => {
                if (result.value) {
                    window.location.href = newurl;
                }
            });
        } else {
            window.location.href = newurl;
        }
    };

    componentDidMount = () => {
        /***
         * Shortcuts
         */
        mt.bind('p', () => {
            if (!this.props.playing) {
                this.props.runProject();
            } else {
                this.props.pauseProject();
            }
            return false;
        });
        mt.bind('r', () => {
            if (!this.props.playing) {
                this.props.resetProject();
            }
            return false;
        });
    };

    componentWillUnmount = () => {
        mt.unbind('r');
        mt.unbind('p');
    };

    render() {
        const { open } = this.state;
        const { t, i18n } = this.props;

        if ((LevelManager.levelTitleUpdate || this.state.levelTitle === '\u200B') && LevelManager.levelTitle !== '\u200B') {
            LevelManager.levelTitleUpdate = false;
            this.setState({ levelTitle: LevelManager.levelTitle });
        }

        let playPauseButton;
        if (!this.props.playing) {
            playPauseButton =
                <IconButton color="inherit" id="play" onClick={this.props.runProject}
                    title={t('Tooltips.PLAY_PROJECT')}><PlayArrowIcon /></IconButton>;
        } else {
            playPauseButton =
                <IconButton color="inherit" id="pause" onClick={this.props.pauseProject}
                    title={t('Tooltips.PLAY_PROJECT')}><PauseIcon /></IconButton>;
        }

        if (AppRoot.INSTANCE.view === "embedded" && (window as any).analytics.getProjectExecutionsCount() === 0) {
            playPauseButton =
                <div style={{ animation: "blinkingBackground 2s infinite", borderRadius: "50%" }}>{playPauseButton}</div>;
        }

        return (
            <div>
                <AppBar position="static" color="default" elevation={0}>
                    <Toolbar>
                        {
                            AppRoot.INSTANCE.view !== "embedded" &&
                            <Button
                                onClick={this.handleProjectMenu}
                                color="inherit"
                            >
                                <DehazeIcon style={{ paddingRight: '5px' }} />
                                {t('MENU.MENU_TITLE')}
                            </Button>
                        }
                        <ProjectTitleInput value={this.state.levelTitle} onChange={this.handleLevelNameChange}
                            onMouseDown={this.onMouseDown}
                            readOnly={AppRoot.INSTANCE.view === "embedded"} />
                        {
                            playPauseButton
                        }
                        <IconButton color="inherit" onClick={this.props.resetProject}
                            title={t('Tooltips.RESET_PROJECT')}><ReplayIcon /></IconButton>
                        {LevelManager.isBuildMode() &&
                            <IconButton color="inherit" onClick={this.props.setAllStartPositions}
                                title={t('Tooltips.SET_ALL_START_POSITIONS')}><LocationOnIcon /></IconButton>
                        }
                        <div style={{ display: "flex", flexGrow: 1 }} />
                        <a href={"https://www.it-for-kids.org"} target={"_blank"} rel={"noopener noreferrer"}
                            style={{ marginRight: "10px" }}>
                            <img style={{ marginTop: "5px" }} height="40px" src={i4klogo} alt="it4kids" />
                        </a>
                        <a href={"https://www.junior-programme.de/"} target={"_blank"} rel={"noopener noreferrer"}
                            style={{ marginRight: "10px" }}>
                            <img style={{ marginTop: "5px" }} width="100px" src={juniorprimologo} alt="juniorprimo" />
                        </a>
                    </Toolbar>
                </AppBar>
                <Drawer
                    open={open}
                    anchor={'left'}
                    onClose={this.handleClose}
                >
                    <List subheader={<ListSubheader>{t('MENU.MENU_TITLE')}</ListSubheader>}>
                        <ListItem button onClick={() => {
                            if (AppRoot.INSTANCE.levelManager && AppRoot.INSTANCE.levelManager.downloadNecessary) {
                                Swal.fire({
                                    text: t('Dialogs.NewProjectConfirm'),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: t('Dialogs.ButtonYes'),
                                    cancelButtonText: t('Dialogs.ButtonNo'),
                                    confirmButtonColor: '#FD5151',
                                    cancelButtonColor: '#00A2E8',
                                    heightAuto: false
                                }).then((result) => {
                                    if (result.value) {
                                        this.props.newLevel();
                                        this.handleClose();
                                    }
                                });
                            } else {
                                this.props.newLevel();
                                this.handleClose();
                            }
                        }}>
                            <ListItemIcon>
                                <NoteAddIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.NEW_PROJECT')} />
                        </ListItem>
                        <LevelLoadModal trigger={<ListItem button>
                            <ListItemIcon>
                                <FolderIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.OPEN_PROJECT')} />
                        </ListItem>} parent={this} />
                        <LevelDownloadModal trigger={<ListItem button>
                            <ListItemIcon>
                                <SaveIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.SAVE_PROJECT')} />
                        </ListItem>} parent={this} />

                        {
                            LevelManager.isBuildMode() &&
                            <LevelExportModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <SaveAltIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.EXPORT_PROJECT')} />
                                </ListItem>}
                                parent={this} />
                        }

                        <LevelRestoreModal trigger={<ListItem button>
                            <ListItemIcon>
                                <ReplayIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.RESTORE_PROJECT')} />
                        </ListItem>} parent={this} />
                        <ListItem>
                            <ListItemIcon>
                                <TranslateIcon color={"secondary"} />
                            </ListItemIcon>
                            <FormControl fullWidth>
                                <Select
                                    id="language-select"
                                    value={i18n.resolvedLanguage}
                                    label="language"
                                    onChange={this.onChangeLanguage}
                                >
                                    <MenuItem value={'de'}>Deutsch</MenuItem>
                                    <MenuItem value={'en'}>English</MenuItem>
                                    <MenuItem value={'ua'}>Українська (Ukrainian)</MenuItem>
                                    <MenuItem value={'fr'}>Français</MenuItem>
                                    <MenuItem value={'es'}>Español</MenuItem>
                                    <MenuItem value={'nl'}>Nederlands</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                    </List>
                    <Divider />
                    <List subheader={<ListSubheader>{t('MENU.ADVANCED_TITLE')}</ListSubheader>}>
                        {!LevelManager.isBuildMode() && <PasswordModal
                            trigger={<ListItem button>
                                <ListItemIcon>
                                    <BrushIcon color={"secondary"} />
                                </ListItemIcon>
                                <ListItemText>{t('MENU.BUILDMODE')}</ListItemText>
                            </ListItem>}
                            parent={this}
                        />}
                        {
                            LevelManager.isBuildMode() &&
                            <SettingsModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <SettingsIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.PROJECT_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {
                            LevelManager.isBuildMode() &&
                            <ToolboxCreatorModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <TuneIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.TOOLBOX_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {
                            LevelManager.isBuildMode() &&
                            <AnalyticsModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <ShowChartIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.ANALYTICS_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {LevelManager.isBuildMode() && <ListItem button onClick={() => {
                            LevelManager.toggleUserMode();
                            this.forceUpdate();
                        }}
                        >
                            <ListItemIcon>
                                <RemoveIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText>{t('MENU.PLAYMODE')}</ListItemText>
                        </ListItem>}


                    </List>
                </Drawer>
            </div>
        );
    }
}

export default withTranslation()(MenuAppBar);

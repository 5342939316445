import * as React from 'react';
import * as PIXI from 'pixi.js-legacy';
import { MIPMAP_MODES } from 'pixi.js-legacy';
import RenderUtils from '../render_utils';
import FlatWorld from '../world';

interface PixiAppProps {
    readonly world: FlatWorld;
}

export default class PixiApp extends React.Component<PixiAppProps> {
    private app: PIXI.Application;
    private world: FlatWorld;
    private pixiDiv: React.RefObject<HTMLDivElement>;
    public ratio: number;
    public scaleChanged: boolean;

    constructor(props: PixiAppProps) {
        super(props);
        PIXI.settings.MIPMAP_TEXTURES = MIPMAP_MODES.ON;
        this.world = props.world;
        this.app = new PIXI.Application({antialias: false, resolution: window.devicePixelRatio || 1});
        this.app.renderer.backgroundColor = 0xf5f5f5;
        this.world.registerContainers(this.app.stage);
        this.pixiDiv = React.createRef();
        this.ratio = window.devicePixelRatio;
        this.scaleChanged = false;
    }

    componentDidMount() {
        this.pixiDiv.current!.appendChild(this.app.view);
        window.addEventListener("resize", this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.windowResized);
    }

    windowResized = () => {
        const appWidth = this.pixiDiv.current!.getBoundingClientRect().width;
        let renderUtils = (window as any).renderUtils as RenderUtils;
        if (this.scaleChanged) {
            const oldScale = this.world.scale;
            this.world.setScale(1 / window.devicePixelRatio);
            const factor = this.world.scale / oldScale;
            this.world.container.position.x *= factor;
            this.world.container.position.y *= factor;
            this.scaleChanged = false;
        }
        renderUtils.setResolution(appWidth / FlatWorld.worldSize().width / this.world.scale);
    };

    getRenderer() {
        return this.app.renderer;
    }

    render() {
        return (
            <div id={"pixi-app-container"} ref={this.pixiDiv} style={{backgroundColor: '#B5E2FA'}}/>
        );
    }
}

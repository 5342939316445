import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { LevelLoadModal, LevelObject, BASE_LEVEL_URL } from 'src/containers/LevelLoadModal';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Grid from '@material-ui/core/Grid/Grid';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Rating from '@material-ui/lab/Rating/Rating';
import LevelManager from 'src/levels';
import i18n from "i18next";
import Storage from "src/utils/storage";
import { getStaticLink, CloudLevel } from 'src/utils/awsCloud';
import tiger from 'src/assets/img/tiger.png';
import { WithTranslation, withTranslation } from 'react-i18next';
import { I18NFallbackLanguage } from 'src/i18n/i18n';
import environment from 'src/environment';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface ListItemsProps {
  parent: LevelLoadModal;
  levelConfig: Array<LevelObject>;
}

function ListItemsTemplates(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.template_exists) { return null; }
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Templates/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.language] || level.title[I18NFallbackLanguage]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={tiger} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={tiger} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.language] || level.description[I18NFallbackLanguage]}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function ListItemsSolutions(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.solution_exists) { return null; }
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Solutions/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.language]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={tiger} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={tiger} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.language]}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function ListItemsCloud(props: ListItemsProps) {
  const { parent } = props;
  let jsx: any[] = [];
  let cloudLevels = Storage.get('CloudLevels', []) as CloudLevel[];
  cloudLevels = cloudLevels.reverse();

  cloudLevels.map((level, index) => {
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(getStaticLink(level.key))}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={8}>
            {level.title}
          </Grid>
          <Grid item={true} xs={4} style={{ textAlign: "right" }}>
            <span style={{ fontSize: "0.75rem", fontStyle: "italic" }}>{(new Date(level.date)).toLocaleString()}</span>
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.key}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
}));

interface LeveLoadModalTabsProps extends WithTranslation {
  parent: LevelLoadModal;
  levelConfig: Array<LevelObject>;
}

function LevelLoadModalTabs(props: LeveLoadModalTabsProps) {
  const classes = useStyles();
  const { parent, levelConfig, t } = props;

  let indices = [0];
  indices.push(LevelManager.isBuildMode() ? Math.max.apply(null, indices) + 1 : -1);
  indices.push(Storage.get('CloudLevels') ? Math.max.apply(null, indices) + 1 : -1);
  const initialTab = (environment.ENV_NAME === 'dev' || Storage.get('CloudLevels')) ? Math.max.apply(null, indices) : 0;
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label={t('LevelLoadModal.Tabs.Templates')} {...a11yProps(indices[0])} />
        {indices[1] > -1 && <Tab label={t('LevelLoadModal.Tabs.Solutions')} {...a11yProps(indices[1])} />}
        {indices[2] > -1 && <Tab label={t('LevelLoadModal.Tabs.Cloud')} {...a11yProps(indices[2])} />}
      </Tabs>
      <TabPanel value={value} index={indices[0]}>
        <List disablePadding={true} className={"levelLoadModalList"}>
          {
            ListItemsTemplates({ parent, levelConfig }).map(component => {
              return component;
            })
          }
        </List>
      </TabPanel>
      {indices[1] > -1 && <TabPanel value={value} index={indices[1]}>
        <List disablePadding={true} className={"levelLoadModalList"}>
          {
            ListItemsSolutions({ parent, levelConfig }).map(component => {
              return component;
            })
          }
        </List>
      </TabPanel>}
      {indices[2] > -1 && <TabPanel value={value} index={indices[2]}>
        <List disablePadding={true} className={"levelLoadModalList"}>
          {
            ListItemsCloud({ parent, levelConfig }).map(component => {
              return component;
            })
          }
        </List>
      </TabPanel>}
    </div>
  );
}

export default withTranslation()(LevelLoadModalTabs);
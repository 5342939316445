import * as React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LevelManager from '../levels';
import { AppRoot } from "../app";
import { WithTranslation, withTranslation } from 'react-i18next';
import Save from '@material-ui/icons/Save';
import mt from 'mousetrap';
import { SlideUp } from "./transitions";
import {showDownload} from "../utils/awsCloud";

/**
 * The props for the LevelDownloadModal
 */
interface LevelDownlaodProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

/**
 * The state for the LevelDownloadModal
 */
interface LevelDownloadState {
    open: boolean;
    name: string;
    levelAccessKey: string;
    showProgress: boolean;
}

/**
 * Displays a modal that allows saving the level to a local file.
 */
class LevelDownloadModal extends React.Component<LevelDownlaodProps, LevelDownloadState> {
    constructor(props: LevelDownlaodProps) {
        super(props);
        this.state = { open: false, name: 'Level', levelAccessKey: '', showProgress: true };
    }

    /**
     * Shortcut
     */
    componentDidMount(): void {
        mt.bind('ctrl+s', () => {
            if (!AppRoot.INSTANCE.state.playing) {
                this.open();
            }
            return false;
        });
    }

    /**
     * Opens the modal.
     */
    open = () => {
        this.setState({ open: true });
        this.setState({ name: LevelManager.levelTitle });
    };

    /**
     * Closes the modal.
     */
    close = () => {
        this.setState({open: false});
        this.props.parent.handleClose();
    };

    /**
     * Same as close but does not close parent.
     */
    closeWithoutParent = () => {
        this.setState({open: false});
    };

    /**
     * Shows the browsers download-file dialog and closes the modal.
     */
    save = () => {
        showDownload(this.state.name);
        this.close();
    };

    /**
     * Changes the filename after the user inputs it.
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ name: event.target.value });
    };

    /**
     * Submits on enter key event.
     */
    handleKeyInput = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.save();
        }
    };

    render() {
        const { t } = this.props;
        return ([
            React.cloneElement(this.props.trigger, { key: "1", onClick: this.open }),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">
                        {t('LevelDownloadModal.title')}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            id="project-name"
                            label={t('LevelDownloadModal.NameFieldLabel')}
                            value={this.state.name}
                            onChange={this.handleChange}
                            fullWidth={true}
                            onKeyPress={this.handleKeyInput}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.save} color="primary" startIcon={<Save />}>
                            {t('LevelDownloadModal.DownloadLevel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(LevelDownloadModal);

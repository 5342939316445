import EventEmitter from "eventemitter3";
import FlatWorld from "../world";
import { AppRoot, notifyUser } from "../app";
import { downloadFileFromCloud } from "../utils/awsCloud";
import { Logging } from 'src/utils/logging';
import Auxiliary from "src/utils/auxiliary";
import LevelManager, { LoadingTypes } from '../levels';
import { WinStates } from "src/level-state";
import WorkspaceManager from "src/workspace";
import { translate } from "src/i18n/i18n";
import { GamemasterModalInstance } from "../containers/GamemasterModal";
import { TutorialTabInstance } from '../containers/SpriteEditor/TutorialTab';

export default class Utils {

    fireEvent: (event: string, ...args: any[]) => boolean;

    private _world: FlatWorld;

    constructor(eventEmitter: EventEmitter, world: FlatWorld) {
        this.fireEvent = eventEmitter.emit.bind(eventEmitter);
        this._world = world;
    }

    random(min: number, max: number) {
        if (Number.isInteger(min) && Number.isInteger(max)) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        } else {
            return Math.random() * (max - min) + min;
        }
    }

    showMonitor(variable: string) {
        const monitors = this._world.getMonitorsById(variable);
        monitors.forEach((m) => m.visible = true);
    }

    hideMonitor(variable: string) {
        const monitors = this._world.getMonitorsById(variable);
        monitors.forEach((m) => m.visible = false);
    }

    notify(text: string, message_type: number) {
        switch (message_type) {

            case 1: {
                notifyUser(text, {variant: 'success'});
                break;
            }
            case 2: {
                notifyUser(text, {variant: 'error'});
                break;
            }
            case 3: {
                notifyUser(text, {variant: 'warning'});
                break;
            }
            default:
                notifyUser("message_type incorrect", {variant: "error"});
        }
    }

    popUp(url: string) {
        //window.open(url , "_blank", "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes");
        if (Auxiliary.isValidUrl(url)) {
            window.open(url, "_blank", "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes");
        } else {
            Logging.error(translate('Messages.InvalidUrl'), true);
        }
    }

    getSpriteFromID(id: number) {
        let sprite = this._world.sprites[id];
        return sprite;
    }

    setWinState(state: WinStates) {
        LevelManager.winState.state = state;
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        workspaceManager.eventEmitter.emit('gameover', state.toString());
    }

    loadLevelFromString(key: string) {
        downloadFileFromCloud(key)
            //add user feedback (load/error)
            .then(res => {
                if (res === "") {
                    return;
                }
                AppRoot.INSTANCE.resetProject();
                let file = JSON.stringify(res);
                let levelManager = (window as any).levelManager as LevelManager;
                levelManager.importFile(file as string, {type: LoadingTypes.DIRECT_JSON});
            });
    }

    stopLevel() {
        notifyUser("Stop", {variant: 'warning'});
        AppRoot.INSTANCE.stopProject();
    }


    openIframe(url: string, title: string, buttons: number, lvlcode: string){
        if (Auxiliary.isValidUrl(url))
        {
            switch (buttons) {

                case 1: {
                    GamemasterModalInstance.INSTANCE.open(url, title, true, lvlcode);
                    break;
                }
                case 2: {
                    GamemasterModalInstance.INSTANCE.open(url, title, false, lvlcode);
                    break;
                }
                default:
                    GamemasterModalInstance.INSTANCE.open(url, title, false, lvlcode);
            }
        }
        else
        {

            Logging.error(translate('Messages.InvalidUrl'), true);
        }
        return ("");
    }

    tutorialNextPage() {
        if (LevelManager.tutorial) {
            LevelManager.tutorial.currentPageIndex = Math.min(LevelManager.tutorial.currentPageIndex + 1, LevelManager.tutorial.pages.length - 1);
            TutorialTabInstance.INSTANCE?.setState({currentPageIndex: LevelManager.tutorial.currentPageIndex})
        }
    }

    tutorialPreviousPage() {
        if (LevelManager.tutorial) {
            LevelManager.tutorial.currentPageIndex = Math.max(LevelManager.tutorial.currentPageIndex - 1, 0);
            TutorialTabInstance.INSTANCE?.setState({currentPageIndex: LevelManager.tutorial.currentPageIndex})
        }
    }

    tutorialSetPage(page: number) {
        if (LevelManager.tutorial) {
            LevelManager.tutorial.currentPageIndex = Math.max(Math.min(page - 1, LevelManager.tutorial.pages.length - 1), 0);
            TutorialTabInstance.INSTANCE?.setState({currentPageIndex: LevelManager.tutorial.currentPageIndex})
        }
    }


    public static audioFiles: Record<string, AudioFile> = {};

    playAudioAsync(url: string, volume: number, callback: Function){
        let audiofile = Utils.audioFiles[url]
        if(!audiofile){
            console.error("audiofile undefined")
            return
        }
        let audioPlayer = audiofile.audioPlayer;
        audioPlayer.volume = volume
        audioPlayer.play();
        setTimeout(() => {
            callback();
        }, audioPlayer.duration * 1000)
    }

}

interface AudioFile {
    audioPlayer: HTMLAudioElement,
    loaded: Promise<void> | null
}

import { Blockly, EventBlocks, EventFilter } from "./blocks";
import Input from "./input";
import CodeRunner, { JSProgram, PropertyItem, Thread } from "./interpreter";
import Sprite from "./sprite";
import Api from "./api/api";
import LevelManager, { LoadingInfo, Serializeable } from "./levels";
import FlatWorld from './world';
import Toolbox from './toolbox';
import RenderUtils from './render_utils';
import './utils/blocklyPlusMinus';

import * as acorn from 'acorn';

import { Logging } from './utils/logging';
import EventEmitter from "eventemitter3";

import Base64 from './utils/b64';
import { themePatcher } from "./theme/themePatcher";
import Monitor from "./monitor";

import {
    BackgroundSavedState,
    SavedState,
    SpriteSavedState,
    Variable,
    WorkspaceManagerSavedState,
    WorkspaceSavedState
} from "./level-state";
import { AppRoot } from "./app";

import isMobile from 'ismobilejs';

/**
 * A WorkspaceManager can be used for displaying and handling multiple
 * {@link Workspace} instances, which are wrappers around a Blockly workspace.
 * It also includes a new sprite.
 * Takes the parenDivId to inject itself.
 *
 * @class WorkspaceManager
 *
 * @param {string} parentDivId The id set on the injection HTML element.
 * @param {(string|HTMLElement)} [toolbox=] The toolbox as a tree of nodes or string representation.
 *
 * @property {HTMLElement} parentDiv The parent div HTML element.
 * @property {FlatWorld} world The world containing the sprites.
 * @property {[Workspace]} workspaces All workspaces known to this instance.
 * @property {string} toolbox The toolbox used for all workspaces.
 * @property {Workspace} activeWorkspace Currently visible workspace.
 * @property {Input} input Service handler for managing input events.
 * @property {Object.<Workspace, Sprite>} sprites A linking of workspaces and their sprites.
 * @property {CodeRunner} codeRunner A interpreter for running code.
 * @property api Our API for all sprites. See /api/.
 *
 * @constructor
 * @requires Workspace
 */

export default class WorkspaceManager implements Serializeable {

    parentDiv: HTMLElement;
    world: FlatWorld;
    workspaces: Workspace[];
    activeWorkspace: Workspace | undefined;
    initialWorkspaceID: number;
    eventEmitter: EventEmitter;
    input: Input;
    codeRunner: CodeRunner;
    api: Api;
    variables: Variable[];
    onActiveWorkspaceChanged: (ws: Workspace, sprite: Sprite | null) => void;

    static propertyItemsFromVariables(program: JSProgram, variables: Variable[]) {
        const world = (window as any).world as FlatWorld;
        return variables.map((variable) => {
            const varName = Blockly.JavaScript.variableDB_.getName(variable.id, Blockly.Variables.NAME_TYPE);
            return program.createPrimitiveProp(varName, null, {
                get: () => variable.value,
                set: (val: any) => {
                    variable.value = val;
                    world.monitorEventValueChanged(variable.id, val);
                }
            });
        });
    }

    deserialize(savedState: SavedState, loadingInfo: LoadingInfo) {
        Blockly.Blocks['receive'].loaded = false;
        Logging.debug("Deserializing workspaces from saved state.");
        let workspaceManagerSavedState = savedState as WorkspaceManagerSavedState;

        this.world.updateOrigin(workspaceManagerSavedState.settings.centerAsOrigin);

        let workspaceInitList: { workspace: Workspace, spriteSavedState: SpriteSavedState | BackgroundSavedState, workspaceSavedState: WorkspaceSavedState }[] = [];

        workspaceManagerSavedState.background.id = 0;
        Logging.debug(`Deserializing sprite with ID ${workspaceManagerSavedState.background.id}.`);
        let background = Sprite.deserialize(workspaceManagerSavedState.background, this.world.getCenter());
        background.updateCostume();
        let backgroundToolbox = new Toolbox(workspaceManagerSavedState.backgroundWorkspace.toolbox);
        let backgroundWorkspace = this.new(background, backgroundToolbox);
        workspaceInitList.push({
            workspace: backgroundWorkspace,
            spriteSavedState: workspaceManagerSavedState.background,
            workspaceSavedState: workspaceManagerSavedState.backgroundWorkspace
        });

        workspaceManagerSavedState.monitors.forEach((monitor) => this.world.registerMonitor(Monitor.deserialize(monitor)));

        let lastID = 1;
        workspaceManagerSavedState.workspaces.forEach((workspaceSavedState) => {
            if (workspaceSavedState.sprite.mobileOnly && !LevelManager.isBuildMode()) {
                try {
                    document.createEvent('TouchEvent');
                    if (!isMobile(window.navigator).any) {
                        throw new Error('desktopWithTouch');
                    }
                } catch (e) {
                    if (workspaceManagerSavedState.settings.selectedWorkspaceID >= workspaceSavedState.sprite.id && workspaceManagerSavedState.settings.selectedWorkspaceID > 0) {
                        workspaceManagerSavedState.settings.selectedWorkspaceID -= 1;
                    }
                    return;
                }
            }

            workspaceSavedState.sprite.id = lastID++;
            Logging.debug(`Deserializing sprite with ID ${workspaceSavedState.sprite.id}.`);

            let sprite = Sprite.deserialize(workspaceSavedState.sprite);
            sprite.updateCostume();

            let toolbox = new Toolbox(workspaceSavedState.workspace.toolbox);
            let workspace = this.new(sprite, toolbox);
            workspaceInitList.push({
                workspace: workspace,
                spriteSavedState: workspaceSavedState.sprite,
                workspaceSavedState: workspaceSavedState.workspace
            });
        });

        workspaceInitList.forEach((item) => {
            //Workspace needs to visible before it's run, otherwise it won't be rendered correctly.
            this.setActive(item.workspace, true);
            item.workspace.init(item.workspaceSavedState);
        });

        //all workspaces need to be loaded once to iterate over blocks
        workspaceInitList.forEach(item => {
            this.setActive(item.workspace, true);
            item.workspace.blocklyWorkspace.getBlocksByType('receive', false).forEach((block: any) => {
                let value = block.getFieldValue('event_name');
                block.getInput('input').removeField('event_name');
                //@ts-ignore because we are certain <eventList> exist on block type <receive>
                block.getInput('input').insertFieldAt(1, new Blockly.FieldDropdown(block.eventList.bind(block)), 'event_name');
                block.setFieldValue(value, 'event_name');
            });
            item.workspace.blocklyWorkspace.getAllBlocks(false).forEach((block: any) => {
                block.applyHide();
            });
        });
        Blockly.Blocks['receive'].loaded = true;

        // the blockly workspace variables are already included in the workspace xml
        // this initializes the values for the interpreter and verifies the name and id
        workspaceManagerSavedState.variables.forEach((variable) => this.createVariable(variable));
        if (this.variables.length === 0) {
            this.createVariable({
                id: "__default__",
                name: "Variable",
                value: ""
            });
        }

        if (this.world.sprites[workspaceManagerSavedState.settings.selectedWorkspaceID]?.programmable || LevelManager.isBuildMode()) {
            this.setActive(this.workspaces[workspaceManagerSavedState.settings.selectedWorkspaceID]);
        } else {
            for (let i = 0; i <= this.world.sprites.length; i++) {
                if (this.world.sprites[i].programmable) {
                    this.setActive(this.workspaces[i]);
                    break;
                }
            }
        }

        this.initialWorkspaceID = workspaceManagerSavedState.settings.selectedWorkspaceID;

        const layeredSprites = Array.from(this.world.sprites).sort((a: Sprite, b: Sprite) => {
            return a.layer > b.layer ? 1 : -1;
        });
        layeredSprites.forEach(s => {
            if (s) {
                s.resetLayer();
            }
        });

        let renderUtils = (window as any).renderUtils as RenderUtils;
        window.setTimeout(renderUtils.generateColorPalette.bind(renderUtils), 500);
    }

    constructor(parentDivId: string, world: FlatWorld) {
        this.onActiveWorkspaceChanged = () => (null);
        this.parentDiv = document.getElementById(parentDivId)!;
        this.world = world;
        this.workspaces = [];
        this.activeWorkspace = undefined;
        this.initialWorkspaceID = 0;

        this.variables = [];

        this.eventEmitter = new EventEmitter();
        this.input = new Input(this.eventEmitter);
        Input.workspaceManager = this;
        this.codeRunner = new CodeRunner();
        this.api = new Api(this.input, (window as any).renderUtils, this.codeRunner, this.eventEmitter, world);

        window.addEventListener('resize', this.onResize.bind(this), false);
        this.onResize();
    }

    /**
     * Creates a new workspace <b>and sets it visible/active.</b>.
     *
     * @param {Sprite} sprite The sprite associated with that workspace.
     * @param {Toolbox} toolbox The toolbox of the new workspace.
     *
     * @returns {Workspace} The newly created workspace.
     */
    new(sprite: Sprite, toolbox: Toolbox = new Toolbox()) {
        this.world.registerSprite(sprite);

        const workspaceVisible = sprite.programmable;
        const workspace = new Workspace(this.parentDiv, toolbox, sprite.id, workspaceVisible);
        workspace.blocklyWorkspace.addChangeListener(this.onChange);

        this.workspaces[workspace.id] = workspace;

        sprite.onClick = ((ws: Workspace, clickedSprite: Sprite, emitEvent: Boolean) => {
            if ((!sprite.isInput && sprite.programmable) || LevelManager.isBuildMode()) {
                this.setActive(ws);
            }
            if (AppRoot.INSTANCE.state.playing && emitEvent) {
                this.eventEmitter.emit("clicked", clickedSprite);
            }
        }).bind(this, workspace);

        if (sprite.programmable || LevelManager.isBuildMode()) {
            this.setActive(workspace);
        }

        // add global variables
        this.variables.forEach((v) => workspace.blocklyWorkspace.createVariable(v.name, "", v.id));
        return workspace;
    }

    getVariableById(id: string) {
        return this.variables.find((v) => v.id === id) || null;
    }

    createVariable(variable: Variable, type: string = "") {
        this.workspaces.forEach((ws) => ws.blocklyWorkspace.createVariable(variable.name, type, variable.id));
        this.variables.push(variable);
        this.world.monitorEventValueChanged(variable.id, variable.value);
    }

    renameVariable(variable: Variable, newName: string) {
        if (variable.name !== newName) {
            this.workspaces.forEach((ws) => ws.blocklyWorkspace.renameVariableById(variable.id, newName));
            variable.name = newName;
            this.world.monitorEventRename(variable.id, newName);
        }
    }

    deleteVariable(variable: Variable) {
        this.variables = this.variables.filter((v) => v !== variable);
        this.workspaces.forEach((ws) => {
            const blocklyVar = ws.blocklyWorkspace.getVariableById(variable.id);
            if (blocklyVar) {
                // TODO: force this or check for uses in all workspaces
                ws.blocklyWorkspace.deleteVariableById(variable.id);
            }
        });
        this.world.monitorEventDeleted(variable.id);
    }

    registerInterpreterInterface(program: JSProgram) {
        let props: PropertyItem[] = [
            program.createNonPrimitiveProp("api", this.api, JSProgram.ReadonlyDescriptor),
            program.createPrimitiveProp("alert", alert, JSProgram.ReadonlyDescriptor),
        ];
        return props.concat(WorkspaceManager.propertyItemsFromVariables(program, this.variables));
    }

    removeWorkspaceById(id: number) {
        Logging.info(`Removing workspace ${id}.`);
        if (this.activeWorkspace && this.activeWorkspace.id === id) {
            const newActive = this.workspaces.find((ws) => this.getSprite(ws).programmable || LevelManager.isBuildMode());
            if (newActive) {
                this.setActive(newActive);
            }
        }
        this.workspaces[id].destroy();
        delete this.workspaces[id];
        this.clearEvents();
        this.registerEvents();
    }

    /**
     * Removes a workspace.
     */
    removeWorkspace(workspace: Workspace) {
        this.removeWorkspaceById(workspace.id);
    }

    /**
     * Removes all workspaces and variables.
     */
    clear() {
        this.workspaces.forEach((workspace) => workspace.destroy());
        this.workspaces = [];
        this.clearEvents();
        this.variables = [];
    }

    /**
     * Sets a workspace active/visible.
     * Only works, if the provided workspace is known to the manager.
     *
     * @param {Workspace} workspace The workspace to set active.
     */
    setActive(workspace: Workspace, quick: boolean = false) {
        if (this.workspaces.indexOf(workspace) !== -1) {
            if (!quick && document.activeElement instanceof HTMLElement && document.activeElement.nodeName.toLowerCase() === 'input') {
                // @ts-ignore
                document.activeElement.dispatchEvent(new KeyboardEvent('keydown', { 'keyCode': 27 }));
            }
            if (this.activeWorkspace) {
                this.activeWorkspace.hide();
            }
            this.activeWorkspace = workspace;

            this.activeWorkspace.show(quick);
            if (!quick) {
                this.onActiveWorkspaceChanged(workspace, this.getCurrentSprite());
            }
        }
        // TODO Add fail on unknown workspace?
    }

    onChange = (event: any) => {
        // TODO: do not delete all events
        this.clearEvents();
        this.registerEvents();

        if (event.type === Blockly.Events.VAR_DELETE) {
            let variable = this.getVariableById(event.varId);
            if (variable) {
                this.deleteVariable(variable);
            }
        } else if (event.type === Blockly.Events.VAR_RENAME) {
            let variable = this.getVariableById(event.varId);
            if (variable) {
                this.renameVariable(variable, event.newName);
            }
        } else if (event.type === Blockly.Events.UI) {
            if (event.element === "click" && this.activeWorkspace) {
                this.activeWorkspace.onBlockClicked(this, event.blockId);
            }
        }
    };

    clearEvents() {
        this.eventEmitter.removeAllListeners();
    }

    registerEvents() {
        this.workspaces.forEach((workspace) => workspace.registerEvents(this));
    }

    /**
     * Executes all workspaces.
     */
    runStart() {
        this.stopAll();
        Logging.info(`Executing all workspaces.`);
        this.codeRunner.setStartTime();
        let thread = runThreadWithCustomCode(this.codeRunner,
            `api.sensing.isAnythingTouching();`
        );
        thread.interval = 90;
        thread.stepExecution = false;
        this.eventEmitter.emit("start");
    }

    /**
     * Stops execution of all workspaces.
     */
    stopAll() {
        Logging.info(`Stopping all workspaces.`);
        this.codeRunner.stop();
    }

    /**
     * Resize handler for window resize event.
     */
    onResize() {
        if (this.activeWorkspace) {
            this.activeWorkspace.onResize();
        }
        if (AppRoot.INSTANCE.pixiApp.current) {
            const pixiApp = AppRoot.INSTANCE.pixiApp.current;
            if (pixiApp.ratio !== window.devicePixelRatio) {
                pixiApp.ratio = window.devicePixelRatio;
                pixiApp.scaleChanged = true;
                pixiApp.windowResized();
            }
        }
    }

    /**
     * Returns the sprite associated to a workspace.
     *
     * @param workspace
     * @return {Sprite}
     */
    getSprite(workspace: Workspace) {
        return this.world.sprites[workspace.id];
    }

    /**
     * Returns the currently selected sprite.
     *
     * @return {Sprite}
     */
    getCurrentSprite() {
        if (this.activeWorkspace) {
            return this.getSprite(this.activeWorkspace);
        }
        return null;
    }

    serialize(): WorkspaceManagerSavedState {
        Logging.silly(`Serializing ${this}.`);
        let background: BackgroundSavedState;
        let backgroundWorkspace: WorkspaceSavedState;
        let workspaces: {
            sprite: SpriteSavedState;
            workspace: WorkspaceSavedState;
        }[] = [];

        this.workspaces.forEach((workspace) => {
            let sprite = this.getSprite(workspace);
            if (sprite) {
                if (sprite.isStaticBackground) {
                    background = sprite.serialize();
                    backgroundWorkspace = workspace.serialize();
                } else {
                    workspaces.push({
                        //@ts-ignore
                        sprite: sprite.serialize(),
                        workspace: workspace.serialize()
                    });
                }
            }

        });

        // TODO: check if background exists
        return {
            background: background!,
            backgroundWorkspace: backgroundWorkspace!,
            workspaces: workspaces,
            variables: this.variables,
            monitors: this.world.monitors.map((monitor) => monitor.serialize()),
            settings: {
                centerAsOrigin: this.world.centerAsOrigin,
                selectedWorkspaceID: this.initialWorkspaceID,
                allowNewSprite: LevelManager.allowNewSprite
            },
            title: LevelManager.levelTitle,
            tutorial: LevelManager.tutorial,
        };
    }

    toString(): String {
        return `WorkspaceManager`;
    }
}

interface ProgramData {
    topBlockId: string;
    threads: number[];
}

function highlightProgram(workspace: Workspace, programData: ProgramData) {
    let block = workspace.blocklyWorkspace.getBlockById(programData.topBlockId) as Blockly.BlockSvg;
    if (block) {
        block.setStackGlowing(true);
    }
}

function unhighlightProgram(codeRunner: CodeRunner, workspace: Workspace, programData: ProgramData) {
    programData.threads = codeRunner.isRunning(programData.threads);
    if (programData.threads.length === 0) {
        let block = workspace.blocklyWorkspace.getBlockById(programData.topBlockId) as Blockly.BlockSvg;
        if (block) {
            block.setStackGlowing(false);
        }
    }
}

function createProgramCallback(programData: ProgramData, workspaceManager: WorkspaceManager, workspace: Workspace,
    filter: EventFilter, block: Blockly.Block, functionAst: acorn.Node) {
    let sprite = workspaceManager.getSprite(workspace);
    let data: { program: null | JSProgram } = { program: null };

    return function (...args: any[]) {
        sprite.getInstances().forEach((spr) => {
            if (filter(block, spr, ...args) && block.isEnabled()) {
                try {
                    Blockly.JavaScript.init(workspace.blocklyWorkspace);
                    const generatedCode = Blockly.JavaScript.blockToCode(block);
                    const programAst = acorn.parse(generatedCode, { ecmaVersion: 5 });

                    // append function code
                    // TODO: only append used functions
                    for (let j = 0, node; (node = functionAst["body"][j]); j++) {
                        programAst["body"].push(node);
                    }

                    data.program = new JSProgram(programAst, workspace.id, spr.guid);
                    data.program.registerGlobalProps(workspaceManager.registerInterpreterInterface(data.program));
                    data.program.registerGlobalProps(workspace.registerInterpreterInterface(data.program));
                } catch (e) {
                    Logging.error("Parsing failed." + e.toString());
                    return;
                }
                let props = [
                    data.program.createPrimitiveProp("sprite", spr, JSProgram.ReadonlyDescriptor ),
                    data.program.createFunctionProp("getParam", (index: number) => args[index], JSProgram.ReadonlyDescriptor),
                ];
                const thread = workspaceManager.codeRunner.runThread(data.program, props,
                    () => unhighlightProgram(workspaceManager.codeRunner, workspace, programData!));
                programData!.threads.push(thread.id);
                highlightProgram(workspace, programData!);
            }
        });
    };
}

function runThreadWithCustomCode(codeRunner: CodeRunner, code: string) : Thread {
    let node = acorn.parse(code, { ecmaVersion: 5 });
    let program = new JSProgram(node, Number.MIN_SAFE_INTEGER, "");
    let prop = program.createNonPrimitiveProp("api", (window as any).workspaceManager.api, JSProgram.ReadonlyDescriptor)
    return codeRunner.runThread(program, [prop]);
}

/**
 * A Workspace is a wrapper for creating Blockly workspaces.
 * They provide code execution, visibility and more.
 * If you need multiple workspaces, use {@link WorkspaceManager}.
 *
 * @class Workspace
 *
 * @param parent HTML-Element of the parent to inject to. (<b> not the ID</b>)
 * @param toolbox HTML-Element of the toolbox. (<b> not the ID</b>)
 * @param {number} id Add an identifier to the workspace.
 *
 *
 * @property parentDiv Private parent div used for toggling visibility.
 * @property blocklyWorkspace The underlying Blockly workspace.
 * @property {CodeRunner} codeRunner The interpreter associated with this workspace.
 * @property {String} CODE_PREFIX A piece of code prefixed before each run.
 * @constructor
 */
export class Workspace implements Serializeable {

    id: number;
    parentDiv: HTMLDivElement;
    blocklyWorkspace: Blockly.Workspace;
    toolbox: Toolbox;
    variables: Variable[];
    programs: ProgramData[];

    constructor(parent: HTMLElement, toolbox: Toolbox, id: number, visible: boolean = true) {
        // Set id, used for indexing in dicts.
        this.id = id;

        this.toolbox = toolbox;
        // Create parent which we can hide and show instead of manipulating directly.
        this.parentDiv = document.createElement("div");
        this.parentDiv.style.height = "100%";
        if (!visible) {
            this.parentDiv.style.display = 'none';
        }
        parent.insertBefore(this.parentDiv, parent.firstChild);

        this.variables = [];
        this.programs = [];

        // TODO: comments should only be editable in build mode

        // Inject Blockly into new div
        this.blocklyWorkspace = Blockly.inject(
            parent.firstElementChild!,
            {
                comments: LevelManager.isBuildMode(),
                buildMode: LevelManager.isBuildMode(),
                toolbox: toolbox.generateToolbox(),
                theme: themePatcher.getTheme(),
                zoom: {
                    controls: true,
                    wheel: true,
                    startScale: 1.0,
                    maxScale: 3.0,
                    minScale: 0.5,
                    scaleSpeed: 1.05
                },
                media: './resources/blockly/media/'
            });

        this.blocklyWorkspace.addChangeListener(this.onChange);

        // Disable the Blockly resize handler
        // Resize events must be handled only for the active workspace
        // TODO: disable this in Blockly
        let workspaceSvg = this.blocklyWorkspace as Blockly.WorkspaceSvg;
        if (workspaceSvg.resizeHandlerWrapper_) {
            Blockly.unbindEvent_(workspaceSvg.resizeHandlerWrapper_);
            workspaceSvg.setResizeHandlerWrapper([]);
        }
    }

    init(savedState: WorkspaceSavedState) {
        //Workspace needs to visible as this is run, otherwise it won't be rendered correctly.
        const xml = Blockly.Xml.textToDom(Base64.decode(savedState.workspacexml));
        Blockly.Xml.domToWorkspace(xml, this.blocklyWorkspace);
        this.blocklyWorkspace.clearUndo();

        // the blockly workspace variables are already included in the workspace xml
        // this initializes the values for the interpreter and verifies the name and id
        savedState.variables.forEach((variable) => this.createVariable(variable));
    }

    /**
     * Destroys the workspace, removing all HTML.
     */
    destroy() {
        Logging.debug(`Destroying ${this}.`);
        this.parentDiv.parentNode!.removeChild(this.parentDiv);
    }

    createVariable(variable: Variable, type: string = "") {
        this.blocklyWorkspace.createVariable(variable.name, type, variable.id);
        this.variables.push(variable);
        // TODO: avoid globals
        const world = (window as any).world as FlatWorld;
        world.monitorEventValueChanged(variable.id, variable.value);
    }

    renameVariable(variable: Variable, newName: string) {
        if (variable.name !== newName) {
            this.blocklyWorkspace.renameVariableById(variable.id, newName);
            variable.name = newName;
            // TODO: avoid globals
            const world = (window as any).world as FlatWorld;
            world.monitorEventRename(variable.id, newName);
        }
    }

    registerInterpreterInterface(program: JSProgram) {
        return WorkspaceManager.propertyItemsFromVariables(program, this.variables);
    }

    generateFunctions() {
        const topBlocks = this.blocklyWorkspace.getTopBlocks(true);
        for (let i = 0; i < topBlocks.length; i++) {
            const block = topBlocks[i];
            if (block.type === "procedures_defnoreturn" || block.type === "procedures_defreturn") {
                Blockly.JavaScript.blockToCode(block);
            }
        }

        var definitions = [];
        for (var name in Blockly.JavaScript.definitions_) {
            if (name.startsWith("%")) {
                definitions.push(Blockly.JavaScript.definitions_[name]);
            }
        }
        return definitions.join('\n\n');
    }

    registerEvents(workspaceManager: WorkspaceManager) {
        Blockly.JavaScript.init(this.blocklyWorkspace);
        const functionAst = acorn.parse(this.generateFunctions(), { ecmaVersion: 5 });
        const topBlocks = this.blocklyWorkspace.getTopBlocks(true);
        workspaceManager.world.sprites[this.id].monitoredSprites = [];
        for (let i = 0; i < topBlocks.length; i++) {
            const block = topBlocks[i];
            if (block.type === "touched") {
                let object = block.getFieldValue('object');
                if (object.startsWith("sprite:")) {
                    let otherSpriteId = parseInt(object.slice(7), 10);
                    if (workspaceManager.world.sprites[this.id].monitoredSprites.indexOf(otherSpriteId) === -1) {
                        workspaceManager.world.sprites[this.id].monitoredSprites.push(otherSpriteId);
                    }
                }
            }
            const evBlock = EventBlocks.find((b) => block.type === b.type);
            if (evBlock) {
                let programData = this.getProgramData(block.id);
                const eventCB = createProgramCallback(programData, workspaceManager, this, evBlock.filterFunc, block, functionAst);
                workspaceManager.eventEmitter.on(block.type, eventCB);
            }
        }
    }

    /**
     * Resize handler for this workspace, which simply resizes the Blockly SVG.
     */
    onResize(quick: boolean = false) {
        Logging.silly(`Resizing ${this}.`);
        const ws = this.blocklyWorkspace as Blockly.WorkspaceSvg;
        Blockly.svgResize(ws as Blockly.WorkspaceSvg);
        const flyout = (ws.getToolbox() as any).flyout_ as Blockly.Flyout;
        flyout.autoClose = LevelManager.isBuildMode() ? flyout.getWidth() > ws.getWidth() * 0.4 : true;
    }

    /** Export current workspace state as xml encoded to b64
     *
     * @returns {string} The b64-string of the xml.
     * @see loadB64
     */
    serialize(): WorkspaceSavedState {
        Logging.silly(`Serializing ${this}.`);
        const xml = Blockly.Xml.workspaceToDom(this.blocklyWorkspace);
        const xmlText = Blockly.Xml.domToText(xml);

        let toolbox = undefined;
        if (this.toolbox.generateToolbox() !== Toolbox.getDefaultToolbox()) {
            toolbox = this.toolbox.getActiveBlocks();
        }

        return {
            toolbox: toolbox,
            workspacexml: Base64.encode(xmlText),
            variables: this.variables
        };
    }

    /**
     * Shows the workspace on screen.
     */
    show(quick: boolean = false) {
        Logging.info(`Showing ${this}.`);
        this.parentDiv.style.display = 'block';
        this.onResize(quick);
    }

    /**
     * Hides the workspace.
     */
    hide() {
        Logging.silly(`Hiding ${this}.`);
        this.parentDiv.style.display = 'none';
    }

    /**
     * Sets the workspaces toolbox
     *
     * @param {Toolbox} toolbox
     */
    setToolbox(toolbox: Toolbox) {
        Logging.silly(`Updating toolbox for ${this}.`);
        this.toolbox = toolbox;
        (this.blocklyWorkspace as Blockly.WorkspaceSvg).updateToolbox(toolbox.generateToolbox());
    }

    getProgramData(blockId: string) {
        let programData = this.programs.find((prg) => prg.topBlockId === blockId);
        if (!programData) {
            programData = { topBlockId: blockId, threads: [] };
            this.programs.push(programData);
        }
        return programData;
    }

    onBlockClicked(workspaceManager: WorkspaceManager, blockId: string) {
        if (LevelManager.isBuildMode()) {
            const block = this.blocklyWorkspace.getBlockById(blockId);
            const topBlock = block.getRootBlock();
            let programData = this.getProgramData(topBlock.id);
            programData.threads = workspaceManager.codeRunner.isRunning(programData.threads);
            if (programData.threads.length === 0) {
                Blockly.JavaScript.init(this.blocklyWorkspace);
                const functionAst = acorn.parse(this.generateFunctions(), { ecmaVersion: 5 });
                createProgramCallback(programData, workspaceManager, this, () => true, topBlock, functionAst)();
            } else {
                workspaceManager.codeRunner.stopById(programData.threads);
            }
        }
    }

    // Function for updating analytics when blocks are created or deleted
    onChange = (event: any) => {
        if (event.type === Blockly.Events.BLOCK_DELETE) {
            const oldBlocksTotalCount = (window as any).analytics.blocksTotalCount;
            // Add difference to deleted blocks count
            (window as any).analytics.addBlocksDeletedCount(oldBlocksTotalCount - (window as any).analytics.getBlocksTotalCount());
            this.programs = this.programs.filter((prg) => prg.topBlockId !== event.blockId);
        } else if (event.type === Blockly.Events.BLOCK_CREATE) {
            // Update total blocks in use for all workspaces
            (window as any).analytics.getBlocksTotalCount();
        } else if (event.type === Blockly.Events.BLOCK_CHANGE) {
        } else if (event.type === Blockly.Events.BLOCK_MOVE) {
        } else if (event.type === Blockly.Events.VAR_DELETE) {
            this.variables = this.variables.filter((v) => v.id !== event.varId);
            // TODO: avoid globals
            const world = (window as any).world as FlatWorld;
            world.monitorEventDeleted(event.varId);
        } else if (event.type === Blockly.Events.VAR_RENAME) {
            let variable = this.variables.find((v) => v.id === event.varId);
            if (variable) {
                this.renameVariable(variable, event.newName);
            }
        } else if (event.type === Blockly.Events.UI) {
            if (event.element === "category" && event.newValue) {
                const ws = this.blocklyWorkspace as Blockly.WorkspaceSvg;
                const flyout = (ws.getToolbox() as any).flyout_ as Blockly.Flyout;
                flyout.autoClose = LevelManager.isBuildMode() ? flyout.getWidth() > ws.getWidth() * 0.4 : true;
            }
        }
    };

    toString(): String {
        return `Workspace[${this.id}]`;
    }
}

import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Analytics from '../analytics';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SlideUp } from "./transitions";
import { sa_event } from "src/utils/analytics";
import Delete from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import Crop75 from "@material-ui/icons/Crop75";
import PlayArrow from "@material-ui/icons/PlayArrow";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";
import Timer from "@material-ui/icons/Timer";

/**
 * Props for Analytics Modal
 */
interface AnalyticsProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

/**
 * State for the Analytics Modal
 */
interface AnalyticsState {
    open: boolean;
    time: string;
    executionsCount: number;
    resetCount: number;
    blocksTotalCount: number;
    blocksDeletedCount: number;
}

/**
 * The analytics modal displays information on the current session.
 */
class AnalyticsModal extends React.Component<AnalyticsProps, AnalyticsState> {
    constructor(props: AnalyticsProps) {
        super(props);
        this.state = {
            open: false,
            time: "0",
            executionsCount: 0,
            resetCount: 0,
            blocksTotalCount: 0,
            blocksDeletedCount: 0
        };
    }

    /**
     * Update component
     */
    update() {
        let analytics: Analytics = (window as any).analytics;
        this.setState({
            time: analytics.getTimeExact(),
            executionsCount: analytics.getProjectExecutionsCount(),
            resetCount: analytics.getProjectResetCount(),
            blocksTotalCount: analytics.getBlocksTotalCount(),
            blocksDeletedCount: analytics.getBlocksDeletedCount()
        });
    }

    /**
     * Open dialog
     */
    open = () => {
        this.update();
        this.setState({open: true});
        sa_event('view_analytics');
    };

    /**
     * Close dialog
     */
    close = () => {
        this.setState({open: false});
    };

    render() {
        const {t} = this.props;
        return ([
            React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="form-dialog-title">{t('AnalyticsModal.title')}</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><Timer /></TableCell>
                                    <TableCell>{t('AnalyticsModal.TimeRequired')}</TableCell>
                                    <TableCell>{this.state.time}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><PlayArrow /></TableCell>
                                    <TableCell>{t('AnalyticsModal.ProgramRunCount')}</TableCell>
                                    <TableCell>{this.state.executionsCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><SettingsBackupRestore /></TableCell>
                                    <TableCell>{t('AnalyticsModal.ProgramResetCount')}</TableCell>
                                    <TableCell>{this.state.resetCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Crop75 /></TableCell>
                                    <TableCell>{t('AnalyticsModal.BlocksTotalCount')}</TableCell>
                                    <TableCell>{this.state.blocksTotalCount}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><Delete /></TableCell>
                                    <TableCell>{t('AnalyticsModal.BlocksDeletedCount')}</TableCell>
                                    <TableCell>{this.state.blocksDeletedCount}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary">
                            <Close />
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(AnalyticsModal);

import * as React from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { Avatar, Checkbox, Divider, Grid, IconButton, Menu, MenuItem, Popover, Tab, Tabs, Toolbar, Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography/Typography';
import UploadCostumeModal from '../UploadCostumeModal';
import UrlCostumeModal from '../UrlCostumeModal';
import LibraryCostumeModal from '../LibraryCostumeModal';
import Sprite, { CostumeEntry } from '../../sprite';
import CostumeDisplay from '../components/CostumeDisplay';
import AddIcon from '@material-ui/icons/Add';
import SettingsGroup from '../components/SettingsGroup';
import SettingsItem from '../components/SettingsItem';
import LevelManager from '../../levels';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AppRoot, notifyUser } from "../../app";
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import { translate } from "../../i18n/i18n";
import { Restore } from "../../utils/restore";
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FlatWorld from "../../world";
import WorkspaceManager from 'src/workspace';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Explore } from "@material-ui/icons";
import { SpriteEditInfoField } from './components/SpriteEditorInfoField';
import { SpriteEditorSpriteName } from './components/SpriteEditorSpriteName';
import TutorialTab from './TutorialTab';
import Delete from '@material-ui/icons/Delete';
import LocationOn from '@material-ui/icons/LocationOn';

export enum SpriteEditorTabs {
    Tutorial = "Tutorial",
    Graphics = "Graphics",
    Costumes = "Costumes",
    Permissions = "Permissions"
}

export class SpriteEditorInstance {
    static INSTANCE: SpriteEditor;
    static SPRITEFIELDS: Array<SpriteEditInfoField> = [];

    static readNewValues() {
        for (let e in this.SPRITEFIELDS) {
            this.SPRITEFIELDS[e].readNewValue(true);
        }
        this.INSTANCE.updateDragging(true);
    }
}

interface SpriteEditorProps extends WithTranslation {
    readonly sprite: Sprite | null;
    readonly onSpriteSelected: (sprite: Sprite | null) => void;
    readonly deleteSprite: (sprite: Sprite) => void;
    readonly setStartConfig: (sprite: Sprite) => void;
}

interface SpriteEditorState {
    anchorEl: HTMLElement | undefined;
    popoverAnchorEl: HTMLElement | undefined;
    tabsValue: string;
    dragging: boolean;
    spriteName: string | undefined;
    spriteDropdownOpen: boolean;
    isInitialWs: boolean;
}

export class SpriteEditor extends React.Component<SpriteEditorProps, SpriteEditorState> {

    constructor(props: SpriteEditorProps) {
        super(props);
        SpriteEditorInstance.INSTANCE = this;
        this.state = {
            anchorEl: undefined,
            popoverAnchorEl: undefined,
            tabsValue: LevelManager.tutorial?.tutorialActive ? SpriteEditorTabs.Tutorial : SpriteEditorTabs.Costumes,
            dragging: false,
            spriteName: props.sprite ? props.sprite.name : undefined,
            spriteDropdownOpen: false,
            isInitialWs: false
        };
    }

    getIsInitialWs = () => {
        if ((window as any).workspaceManager && (window as any).workspaceManager.activeWorkspace) {
            return (window as any).workspaceManager.initialWorkspaceID === (window as any).workspaceManager.activeWorkspace!.id;
        }
        return false;
    }

    deleteCurrentSprite = () => {
        if (this.props.sprite) {
            this.props.deleteSprite(this.props.sprite);
        }
    };

    setStartConfigCurrentSprite = () => {
        if (this.props.sprite) {
            const { t, sprite } = this.props;
            this.props.setStartConfig(sprite);
            notifyUser(t('SpriteEditor.NOTIFY_START_POSITION_SET'), { variant: 'success' });
        }
    };

    /**
     * Hide input field for costume name and call callback.
     */
    handleNameInputFocusChanged = () => {
        this.setState({
            spriteName: this.props.sprite ? this.props.sprite.name : "Figur",
        });
    };

    /**
     * Update the internal state for the costume as we type it to reduce lag.
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    handleSpriteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ spriteName: e.target.value });
    };

    saveSpriteName = () => {
        if (this.props.sprite && this.state.spriteName) {
            this.props.sprite.name = this.state.spriteName;
        }
    };

    /**
     * Updates the "z-index" of the sprite layer
     */
    handleSpriteLayerChange = (e: any, index: number | number[]) => {
        if (this.props.sprite && typeof index === 'number') {
            this.props.sprite.layer = index;
        }
    };

    /**
     * Opens the "Add costume" menu.
     * @param event
     */
    handleCostumeMenuOpen = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    /**
     * Closes the "Add costume" menu.
     */
    handleCostumeMenuClose = () => {
        this.setState({ anchorEl: undefined });
    };

    /**
     * Opens the popup menu.
     * @param event
     */
    handlePopOverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ popoverAnchorEl: event.currentTarget, spriteDropdownOpen: true });
    };

    /**
     * Closes the popup menu.
     */
    handlePopOverClose = () => {
        this.setState({ popoverAnchorEl: undefined, spriteDropdownOpen: false });
    };

    /**
     * Delete a costume from the sprite.
     * @param {CostumeEntry} costume
     */
    handleDeleteCostume = (costume: CostumeEntry) => {
        //Intentionally reusing the same ref for the new state.
        if (this.props.sprite) {
            this.props.sprite.removeCostume(costume);
            this.props.onSpriteSelected(this.props.sprite);
            Restore.createRestorePoint(translate('RestoreMessages.costume.deleted') + costume.name);
            (window as any).renderUtils.generateColorPalette();
        }
    };

    /**
     * Set the sprites costume.
     * @param {CostumeEntry} costume
     */
    handleSetCurrentCostume = (costume: CostumeEntry) => {
        const sprite = this.props.sprite;
        if (sprite && sprite.costumes.indexOf(costume) > -1) {
            sprite.costumeId = costume.id;
            sprite.updateCostume();
            (window as any).renderUtils.generateColorPalette();
        }
    };

    /**
     * Returns the number of the current sprites costumes
     */
    getCostumesLength = () => {
        if (this.props.sprite) {
            return this.props.sprite.costumes.length;
        }
        return 0;
    };

    private spriteList: JSX.Element[] = [];

    selectSpriteElement = (sprite: any) => {
        this.updateDragging(true);
        sprite.onClick(this, false);
        this.handlePopOverClose();
        setTimeout(function () {
            SpriteEditorInstance.readNewValues();
        }, 100);
        this.setState({ spriteName: sprite.name, tabsValue: LevelManager.tutorial?.tutorialActive ? SpriteEditorTabs.Tutorial : SpriteEditorTabs.Costumes });
    };

    //check if this works
    createAndOpen = (event: any) => {
        this.createSpriteElements();
        this.handlePopOverOpen(event);
    };

    createSpriteElements = () => {

        let world = (window as any).world as FlatWorld;
        let spriteElements: JSX.Element[] = [];
        const { t } = this.props;

        if (LevelManager.allowNewSprite || LevelManager.isBuildMode()) {
            spriteElements.push(
                <MenuItem
                    key={"spriteEditorDropdown-add-sprite"}
                    onClick={() => {
                        AppRoot.INSTANCE.newSprite();
                        this.setState({ spriteDropdownOpen: false });
                    }}>
                    <Avatar><AddIcon /></Avatar>{["\xa0\xa0", t('MENU.ADD_SPRITE')].join("")}
                </MenuItem>);
            spriteElements.push(<Divider />);
        }

        world.sprites.forEach((sprite) => {
            let index = sprite.costumes.findIndex((c) => c.id === sprite.costumeId);
            // if costume not present, ignore the rest of this function
            if (sprite.costumes[index] === undefined) {
                return;
            }
            if (LevelManager.isBuildMode()) {
                spriteElements.push(
                    <MenuItem
                        key={`spriteEditorDropdown-${sprite.id}-${sprite.costumeId}`}
                        onClick={() => this.selectSpriteElement(sprite)}>
                        <Avatar alt={sprite.name.charAt(0)} src={sprite.costumes[index].image.src} />
                        {["\xa0\xa0", sprite.name].join("")}
                    </MenuItem>);
            } else {
                if (sprite.programmable) {
                    spriteElements.push(
                        <MenuItem
                            key={`spriteEditorDropdown-${sprite.id}-${sprite.costumeId}`}
                            onClick={() => this.selectSpriteElement(sprite)}>
                            <Avatar alt={sprite.name.charAt(0)} src={sprite.costumes[index].image.src} />
                            {["\xa0\xa0", sprite.name].join("")}
                        </MenuItem>);
                }
            }
        });
        this.spriteList = spriteElements;
    };

    handleTabChange = (event: any, value: string) => {
        this.setState({ tabsValue: value });
    };

    handleToggleSystemCostume = (costume: CostumeEntry) => {
        costume.isSystemCostume = !costume.isSystemCostume;
    };

    // keep track of latest dragging update
    private latest: any = {
        x: 0,
        y: 0,
        dragging: false,
        update: Date.now(),
    };
    updateDragging = (force: boolean = false) => {
        // is last update more than 100ms ago?
        if (Date.now() - this.latest.update < 100 && !force) {
            return;
        }
        // check if not playing, sprite is set, and there are any changes
        if ((!AppRoot.INSTANCE.state.playing && this.props.sprite
            && (
                this.props.sprite.x !== this.latest.x
                || this.props.sprite.y !== this.latest.y
                || this.props.sprite.dragging !== this.latest.dragging
            ))
            || (force && this.props.sprite)
        ) {
            // update interface
            this.setState({ dragging: this.props.sprite.dragging });
            // update latest status
            this.latest.x = this.props.sprite.x;
            this.latest.y = this.props.sprite.y;
            this.latest.dragging = this.props.sprite.dragging;
            this.latest.update = Date.now();
        }
    };

    onMouseMovePixi(spriteEditor: SpriteEditor) {
        // create event trigger callback
        return () => {
            if (spriteEditor.props && spriteEditor.props.sprite && spriteEditor.props.sprite.dragging) {
                spriteEditor.updateDragging();
            }
        };
    }

    componentDidMount() {
        // set event listener
        let pixieAppContainer = document.querySelector('#pixi-app-container > canvas') as HTMLElement;
        if (pixieAppContainer) {
            pixieAppContainer.onmousemove = this.onMouseMovePixi(this);
            pixieAppContainer.onmousedown = () => {
                this.setState({ dragging: true });
            };
            pixieAppContainer.onmouseup = () => {
                SpriteEditorInstance.readNewValues();
                this.setState({ dragging: false });
            };
        }
    }

    componentWillUnmount() {
        // reset event listener
        let pixieAppContainer = document.querySelector('#pixi-app-container > canvas') as HTMLElement;
        if (pixieAppContainer) {
            pixieAppContainer.onmousemove = pixieAppContainer.onmousedown = pixieAppContainer.onmouseup = null;
        }
    }

    componentDidUpdate(prevProps: Readonly<SpriteEditorProps>, prevState: Readonly<SpriteEditorState>) {
        if (this.props.sprite !== prevProps.sprite && this.props.sprite) {
            this.setState({ spriteName: this.props.sprite.name });
        }
    }

    render() {
        const { anchorEl, popoverAnchorEl, isInitialWs } = this.state;
        let { tabsValue } = this.state;
        const { t, sprite } = this.props;

        if (this.getIsInitialWs() !== isInitialWs) {
            this.setState({ isInitialWs: this.getIsInitialWs() });
        }

        if (!sprite) {
            return (
                <div>
                    <AppBar position="static" color="default">
                        <Toolbar>
                            <Typography variant="h6" color="inherit">{t('SpriteEditor.NoSpriteSelected')}</Typography>
                        </Toolbar>
                    </AppBar>
                </div>);
        }

        if (AppRoot.INSTANCE.state.playing && AppRoot.INSTANCE.state.loading) {
            this.createSpriteElements();
        }

        // You can't adjust the rendering on a static background
        if (tabsValue === SpriteEditorTabs.Graphics && sprite.isStaticBackground) {
            tabsValue = SpriteEditorTabs.Costumes;
        }

        const menuJSX = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={this.handleCostumeMenuClose}
                keepMounted={true}
            >
                <UploadCostumeModal sprite={sprite}
                    trigger={<MenuItem>{t('SpriteEditor.UploadCostumeModal.title')}</MenuItem>}
                    parent={this} />
                <UrlCostumeModal sprite={sprite} trigger={<MenuItem>{t('SpriteEditor.URLCostumeModalTitle')}</MenuItem>}
                    parent={this} />
                <LibraryCostumeModal sprite={sprite}
                    trigger={<MenuItem>{t('SpriteEditor.LibraryCostumeModalTitle')}</MenuItem>}
                    parent={this} />
            </Menu>);

        let isBackground: boolean = sprite.isStaticBackground;
        let positionNotEditable = AppRoot.INSTANCE.state.playing || this.state.dragging;

        let onlyCostume = sprite.costumes.length <= 1;

        //style={{maxHeight: "120px"}}
        const costumeJSX = (
            <Grid container={true} spacing={2}>
                {
                    sprite.costumes.map((costume, index) => {
                        return (
                            <Grid key={index} item={true} xs={6} md={6} lg={4} xl={4}>
                                <CostumeDisplay
                                    onCostumeClicked={this.handleSetCurrentCostume}
                                    deleteCostume={this.getCostumesLength() > 1 ? this.handleDeleteCostume : undefined}
                                    costume={costume}
                                    readonly={costume.isSystemCostume}
                                    setIsSystemState={LevelManager.isBuildMode() ? this.handleToggleSystemCostume : undefined}
                                    onlyCostume={onlyCostume}
                                />
                            </Grid>);
                    })

                }
                <Grid style={{ display: "flex", justifyContent: " center", alignItems: "center" }} item={true} xs={6} md={6} lg={4} xl={4}>
                    <Tooltip title={t('SpriteEditor.AddCostumeTooltip') as string} disableFocusListener>
                        <Button
                            startIcon={<AddIcon />}
                            color={"secondary"}
                            onClick={this.handleCostumeMenuOpen}
                            disableFocusRipple
                            style={{ verticalAlign: "middle", fontSize: "12px", wordBreak: "break-word", padding: "2rem 1rem" }}>
                            {t('SpriteEditor.AddCostumeTooltip')}
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        );

        const settingsJSX = (
            <div>
                <SettingsGroup title={t('SpriteEditor.Permissions.title') as string}>
                    <SettingsItem title={t('SpriteEditor.Permissions.Costumes.title')}
                        description={t('SpriteEditor.Permissions.Costumes.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.allowCostumeEdit = !sprite.allowCostumeEdit;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.allowCostumeEdit || false}
                        />
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Permissions.Programmable.title')}
                        description={t('SpriteEditor.Permissions.Programmable.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.programmable = !sprite.programmable;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.programmable || false}
                        />
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Permissions.Draggable.title')}
                        description={t('SpriteEditor.Permissions.Draggable.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.draggable = !sprite.draggable;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.draggable || false}
                        />
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Permissions.Deletable.title')}
                        description={t('SpriteEditor.Permissions.Deletable.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.deletable = !sprite.deletable;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.deletable || false}
                        />
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Permissions.IsInput.title')}
                        description={t('SpriteEditor.Permissions.IsInput.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.isInput = !sprite.isInput;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.isInput || false}
                        />
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Permissions.MobileOnly.title')}
                        description={t('SpriteEditor.Permissions.MobileOnly.description')}>
                        <Checkbox
                            onChange={() => {
                                if (sprite) {
                                    sprite.mobileOnly = !sprite.mobileOnly;
                                    this.props.onSpriteSelected(sprite);
                                }
                            }}
                            checked={sprite.mobileOnly || false}
                        />
                    </SettingsItem>
                </SettingsGroup>
            </div>
        );

        const graphicsJSX = (
            <div>
                <SettingsGroup title={t('SpriteEditor.Graphics.title')}>
                    <SettingsItem title={t('SpriteEditor.Graphics.Scale.title')}
                        description={t('SpriteEditor.Graphics.Scale.description')}>
                        <SpriteEditInfoField sprite={sprite} spriteInfo={"scale"}
                            label={t('SpriteEditor.Graphics.Scale.label')} width={50}
                            disabled={positionNotEditable} updateDragging={this.updateDragging}>
                        </SpriteEditInfoField>
                        {
                            (sprite.scale > 1.2 || sprite.scale < 0.8) &&
                            <Typography style={{ fontSize: "0.75rem", fontStyle: "italic", color: "#AF1B3F" }}>
                                {t('SpriteEditor.Graphics.Scale.warning')}
                            </Typography>
                        }
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Graphics.Layer.title')}
                        description={t('SpriteEditor.Graphics.Layer.description')}>
                        <div key={sprite.layer}>
                            <Slider
                                valueLabelDisplay="auto"
                                defaultValue={sprite.layer}
                                onChange={this.handleSpriteLayerChange}
                                min={1}
                                max={(window as any).world.container.children.length - 2}
                                step={2}
                                marks
                                style={{ width: 200 }}
                                disabled={positionNotEditable}
                            />
                        </div>
                    </SettingsItem>
                    <SettingsItem title={t('SpriteEditor.Graphics.Mirror.title')}
                        description={t('SpriteEditor.Graphics.Mirror.description')}>
                        <Button
                            onClick={() => {
                                if (sprite) {
                                    sprite.mirror("X");
                                }
                            }}
                        >
                            {t('SpriteEditor.Graphics.Mirror.btnX-axis')}
                        </Button>
                        <Button
                            onClick={() => {
                                if (sprite) {
                                    sprite.mirror("Y");
                                }
                            }}
                        >
                            {t('SpriteEditor.Graphics.Mirror.btnY-axis')}
                        </Button>
                    </SettingsItem>
                    {
                        sprite.programmable &&
                        <SettingsItem title={t('SpriteEditor.Graphics.InitialWS.title')}
                            description={t('SpriteEditor.Graphics.InitialWS.description')}>
                            <Checkbox
                                checked={isInitialWs}
                                disabled={isInitialWs}
                                onClick={(t) => {
                                    const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
                                    workspaceManager.initialWorkspaceID = workspaceManager.activeWorkspace!.id;
                                    this.setState({ isInitialWs: true });
                                }}
                                title={t('SpriteEditor.Graphics.InitialWS.btnSetInitial')} />
                        </SettingsItem>
                    }
                </SettingsGroup>
            </div>
        );

        let index = sprite.costumes.findIndex((c) => c.id === sprite.costumeId);
        let spriteCurrentCostumeImage = null;
        if (index >= 0) {
            spriteCurrentCostumeImage = sprite.costumes[index].image.src;
        }

        // This is a priority list. The first tab to be active is the fallback if the currently selected one is not active.
        const activeTabs = {
            [SpriteEditorTabs.Tutorial]: LevelManager.tutorial.tutorialActive,
            [SpriteEditorTabs.Costumes]: (LevelManager.isBuildMode() || sprite.allowCostumeEdit),
            [SpriteEditorTabs.Graphics]: (LevelManager.isBuildMode() && !isBackground),
            [SpriteEditorTabs.Permissions]: LevelManager.isBuildMode()
        };

        // Just check if the tabs content should be shown (only if active and selected)
        const canShowTabContent = (t: SpriteEditorTabs): boolean => {
            return activeTabs[t] && tabsValue === t
        }

        // If the currently selected tab is not available, find the first one that is active or fall back to nothing
        if (activeTabs[tabsValue] === false) {
            tabsValue = Object.keys(activeTabs).find(e => activeTabs[e] === true) || 'none';
        }

        return (
            <>
                <AppBar position="static" color="default">
                    <Toolbar style={{ padding: "0px 10px 0px 10px", margin: 0 }}>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignItems: "center", width: "100%", marginBottom: "10px" }}>
                            <div>
                                <SpriteEditorSpriteName
                                    placeholder={t('MENU.ADD_SPRITE')}
                                    value={this.state.spriteName}
                                    onChange={this.handleSpriteNameChange}
                                    onBlur={this.saveSpriteName}
                                    avatarSrc={spriteCurrentCostumeImage}
                                    actionElement={<IconButton size="small" onClick={this.createAndOpen} style={{ display: 'inline' }}>
                                        <ArrowDropDownIcon />
                                    </IconButton>} />

                                <Popover
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={this.state.spriteDropdownOpen}
                                    onClose={() => {
                                        this.setState({ spriteDropdownOpen: false });
                                    }}
                                    anchorEl={popoverAnchorEl}
                                    style={{ maxHeight: '40vh' }}
                                >
                                    {this.spriteList}
                                </Popover>
                            </div>
                            <div>
                                {
                                    (sprite.draggable || LevelManager.isBuildMode()) && !isBackground &&
                                    <Tooltip title={t('SpriteEditor.TooltipSetStartConfig').toString()}>
                                        <IconButton
                                            disabled={positionNotEditable}
                                            size="small"
                                            onClick={this.setStartConfigCurrentSprite} style={{
                                                display: "inline",
                                                margin: "12px 0px 12px 0px"
                                            }}><LocationOn /></IconButton>
                                    </Tooltip>
                                }
                                {
                                    (LevelManager.isBuildMode() || sprite.deletable) && !isBackground &&
                                    <Tooltip title={t('SpriteEditor.TooltipDeleteSprite').toString()}>
                                        <IconButton size="small" onClick={this.deleteCurrentSprite} style={{
                                            display: "inline",
                                            margin: "12px 0px 12px 0px"
                                        }}><Delete/></IconButton>
                                    </Tooltip>
                                }
                                {
                                    LevelManager.isBuildMode() &&
                                    !isBackground &&
                                    <Tooltip title={t('MENU.DUPLICATE_SPRITE').toString()}>
                                        <IconButton size="small" onClick={AppRoot.INSTANCE.duplicateSprite} style={{
                                            display: "inline",
                                            margin: "12px 0px 12px 0px"
                                        }}><FileCopyIcon /></IconButton>
                                    </Tooltip>
                                }
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <div>
                                <SpriteEditInfoField sprite={sprite} spriteInfo={"rotation"}
                                    icon={<Explore style={{ opacity: '0.8' }} />} width={35}
                                    disabled={positionNotEditable}
                                    updateDragging={this.updateDragging} />
                                <SpriteEditInfoField sprite={sprite} spriteInfo={"x"}
                                    icon={<SwapHorizontalCircleIcon style={{ opacity: '0.8' }} />}
                                    width={35} disabled={positionNotEditable}
                                    updateDragging={this.updateDragging} />
                                <SpriteEditInfoField sprite={sprite} spriteInfo={"y"}
                                    icon={<SwapVerticalCircleIcon style={{ opacity: '0.8' }} />}
                                    width={35} disabled={positionNotEditable}
                                    updateDragging={this.updateDragging} />
                            </div>
                        </div>
                    </Toolbar>
                </AppBar>
                {menuJSX}
                {
                    // Only show the row of tabs to select from if we actually have mutliple to choose from
                    Object.values(activeTabs).filter(e => e === true).length > 1 && <Tabs value={tabsValue} onChange={this.handleTabChange} variant="scrollable">
                        {activeTabs[SpriteEditorTabs.Tutorial] && <Tab value={SpriteEditorTabs.Tutorial} label={t('SpriteEditor.TutorialTabLabel')} />}
                        {activeTabs[SpriteEditorTabs.Costumes] && <Tab value={SpriteEditorTabs.Costumes} label={t('SpriteEditor.CostumesTabLabel')} />}
                        {activeTabs[SpriteEditorTabs.Graphics] && <Tab value={SpriteEditorTabs.Graphics} label={t('SpriteEditor.GraphicsTabLabel')} />}
                        {activeTabs[SpriteEditorTabs.Permissions] && <Tab value={SpriteEditorTabs.Permissions} label={t('SpriteEditor.PermissionsTabLabel')} />}
                    </Tabs>
                }
                <div style={{ "flexGrow": 1, height: "100%", overflowY: "auto", padding: "20px" }}>
                    {canShowTabContent(SpriteEditorTabs.Tutorial) && <TutorialTab />}
                    {canShowTabContent(SpriteEditorTabs.Costumes) && costumeJSX}
                    {canShowTabContent(SpriteEditorTabs.Graphics) && graphicsJSX}
                    {canShowTabContent(SpriteEditorTabs.Permissions) && settingsJSX}
                </div>
            </>

        );
    }
}

export default withTranslation()(SpriteEditor);

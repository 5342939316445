import * as PIXI from 'pixi.js-legacy';
import FlatWorld from './world';
import Sprite from './sprite';

// @ts-ignore
import ColorThief from 'colorthief';
import { AppRoot } from "./app";

const colorThief = new ColorThief();

const silhouetteShaderFrag = `
varying vec2 vTextureCoord;
uniform sampler2D uSampler;

void main() {
    gl_FragColor = texture2D(uSampler, vTextureCoord);
    if (gl_FragColor.a != 0.0)
    {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
    }
}
`;

const silhouetteColorMaskShaderFrag = `
varying vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform vec3 uColorMask;

void main() {
    gl_FragColor = texture2D(uSampler, vTextureCoord);
    vec3 colorDiff = abs(gl_FragColor.rgb - uColorMask);
    gl_FragColor.a = 0.0;
    if (all(lessThan(colorDiff, vec3(0.01, 0.01, 0.01))))
    {
        gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
    }
}
`;

/**
 * Utility Class, which provides several collision-checking functions.
 *
 * @param {FlatWorld} world World
 *
 * @property {FlatWorld} world World
 * @property {PIXI.RenderTexture} renderTex Temporary texture
 * @property {PIXI.Container} container Container for rendering to texture
 * @property {PIXI.Filter} silhouetteFilter Filter for creating a silhouette from a texture
 * @property {PIXI.Filter} silhouetteColorMaskFilter Filter for creating a silhouette from a color-masked texture
 *
 * @constructor
 */
export default class RenderUtils {
    world: FlatWorld;
    renderer: PIXI.Renderer | PIXI.CanvasRenderer;
    renderTex: PIXI.RenderTexture;
    container: PIXI.Container;
    silhouetteFilter: PIXI.Filter;
    silhouetteColorMaskFilter: PIXI.Filter;
    colorPalette: string[];

    getStageBounds() {
        let x = 0;
        let y = -FlatWorld.worldSize().height;
        if (this.world.centerAsOrigin) {
            x = -FlatWorld.worldSize().width / 2;
            y = -FlatWorld.worldSize().height / 2;
        }
        return new PIXI.Rectangle(x, y, FlatWorld.worldSize().width, FlatWorld.worldSize().height);
    }

    constructor(world: FlatWorld, renderer: PIXI.Renderer | PIXI.CanvasRenderer) {
        this.world = world;
        this.renderer = renderer;
        this.renderTex = PIXI.RenderTexture.create({ width: FlatWorld.worldSize().width, height: FlatWorld.worldSize().height });
        this.container = new PIXI.Container();
        this.silhouetteFilter = new PIXI.Filter(undefined, silhouetteShaderFrag);
        this.silhouetteFilter.padding = 4;
        this.silhouetteColorMaskFilter = new PIXI.Filter(undefined, silhouetteColorMaskShaderFrag);
        this.silhouetteColorMaskFilter.padding = 4;
        this.colorPalette = ["#ffffff"];
    }

    _checkRenderTex(width: number, height: number) {
        if (this.renderTex.width < width || this.renderTex.height < height) {
            this.renderTex.destroy(true);
            this.renderTex = PIXI.RenderTexture.create({ width: width, height: height });
        }
    }

    generateColorPalette() {
        const canvas = this.renderer.extract.canvas(this.world.container);
        const data = canvas.toDataURL();
        let img = new Image();
        img.addEventListener('load', () => {
            try {
                const ctPalette = colorThief.getPalette(img, 20);
                if (ctPalette === null) {
                    return;
                }
                let newPalette: any[] = [];
                for (let i = 0; i < ctPalette.length; i++) {
                    const color = ctPalette[i];
                    newPalette.push({
                        rgb: color,
                        hex: RenderUtils.rgbToHex(color[0], color[1], color[2]),
                        hsl: RenderUtils.rgbToHsl(color[0], color[1], color[2]),
                        xyz: RenderUtils.rgbToXyz(color[0], color[1], color[2]),
                        flagged: false
                    });
                    let lastEntry = newPalette[newPalette.length - 1];
                    lastEntry.lab = RenderUtils.xyzToLab(lastEntry.xyz[0], lastEntry.xyz[1], lastEntry.xyz[2]);
                    lastEntry.eye = 2 * lastEntry.hsl[0] + 1 - lastEntry.hsl[1] + Math.abs(0.5 - lastEntry.hsl[2]);
                }
                for (let i = 0; i < newPalette.length; i++) {
                    for (let j = 0; j < newPalette.length; j++) {
                        const c1 = newPalette[i];
                        const c2 = newPalette[j];
                        let delta = RenderUtils.deltaE94(c1.lab, c2.lab);
                        if (i !== j && delta < 5 && !c1.flagged && !c2.flagged) {
                            c1.flagged = true;
                        }
                    }
                }
                newPalette = newPalette.filter(function (item: any, pos: any) {
                    return !item.flagged;
                });

                newPalette.sort(function (a, b) {
                    return a.eye < b.eye ? -1 : 1;
                });

                this.colorPalette = [];
                for (let i = 0; i < newPalette.length; i++) {
                    this.colorPalette.push(newPalette[i].hex);
                }
            } finally {
            }
        });
        img.src = data;
    };

    /**
     * Checks if the given sprite touches a certain color.
     *
     * Only objects whose bounding boxes intersect the bounding box of the given sprite are
     * used for the pixel-perfect collision check.
     *
     * @param {Sprite} sprite Sprite to be checked
     * @param {number} color Color to search for
     * @param {number} colorMask Color-mask being applied on the sprite
     * @returns {boolean}
     * @public
     */
    isTouchingColor(sprite: Sprite, color: number, colorMask: number) {
        let otherSprites: Sprite[] = [];
        this.world.sprites.forEach((spr) => {
            otherSprites = otherSprites.concat(spr.getInstances().filter((s) => s !== sprite && this._spritesIntersect(sprite, s)));
        });

        if (otherSprites.length === 0) {
            return false;
        }

        const pixels = this._getCollisionPixels(sprite, otherSprites, colorMask);

        let rgbColor = PIXI.utils.hex2rgb(color);
        rgbColor = [rgbColor[0] * 255, rgbColor[1] * 255, rgbColor[2] * 255];
        for (let i = 0; i < pixels.length; i += 4) {
            const diffR = Math.abs(pixels[i] - rgbColor[0]);
            const diffG = Math.abs(pixels[i + 1] - rgbColor[1]);
            const diffB = Math.abs(pixels[i + 2] - rgbColor[2]);

            if (diffR <= 5 && diffG <= 5 && diffB <= 5) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks if the given sprite touches the mouse cursor.
     *
     * @param {Sprite} sprite Sprite to be checked
     * @param {PIXI.Point} mousePosArg Mouse position
     * @returns {boolean}
     * @public
     */
    isTouchingMouse(sprite: Sprite, mousePosArg?: PIXI.Point) {
        let mousePos;
        if (mousePosArg) {
            mousePos = mousePosArg;
        } else {
            mousePos = this.renderer.plugins.interaction.mouse.getLocalPosition(this.world.container);
            mousePos.y *= -1;
        }

        const pixiSprite = sprite.pixiSprite;

        let tmpPos = new PIXI.Point(mousePos.x - pixiSprite.x, mousePos.y + pixiSprite.y);
        let mat = PIXI.Matrix.IDENTITY;
        mat.rotate(pixiSprite.rotation);
        mat.translate(pixiSprite.width / 2, pixiSprite.height / 2);
        mat.scale(1 / Math.abs(pixiSprite.scale.x), 1 / Math.abs(pixiSprite.scale.y));
        mat.apply(tmpPos, tmpPos);

        const bounds = pixiSprite.getLocalBounds();

        tmpPos.x = Math.floor(tmpPos.x);
        tmpPos.y = Math.floor(tmpPos.y);
        tmpPos.y = bounds.height - tmpPos.y;

        if (tmpPos.x < 0 || tmpPos.y < 0 || tmpPos.x > bounds.width || tmpPos.y > bounds.height) {
            return false;
        }

        //skip transparency check if triggered by sprite selection
        if (mousePosArg) {
            return true;
        }

        // TODO: rework this
        const tmp = new PIXI.Sprite(pixiSprite.texture);
        const pixels = this.renderer.extract.pixels(tmp);
        const alpha = pixels[(tmpPos.y * bounds.width + tmpPos.x) * 4 + 3];

        return alpha > 5;
    }

    /**
     * Checks if the given sprite touches the edge.
     *
     * A pixel-perfect collision check is performed, if the bounding box of the sprite intersects the edges.
     *
     * @param {Sprite} sprite Sprite to be checked
     * @returns {boolean}
     * @public
     */
    isTouchingEdge(sprite: Sprite) {
        if (!sprite.hasNewPosition()) {
            return sprite.cachedTouchEvaluations.edge;
        };
        const stageBounds = this.getStageBounds();
        const spriteBounds = this.getBounds(sprite);

        // completely inside
        if (stageBounds.left < spriteBounds.left && stageBounds.top < spriteBounds.top
            && stageBounds.right > spriteBounds.right && stageBounds.bottom > spriteBounds.bottom) {
            sprite.cachedTouchEvaluations.edge = false;
            return false;
        }

        // completely outside
        if (!RenderUtils.rectIntersect(stageBounds, spriteBounds) && AppRoot.INSTANCE.state.playing) {
            Sprite.eventEmitter.emit("touched", sprite, "edge");
            sprite.cachedTouchEvaluations.edge = true;
            return true;
        }

        // TODO: optimize this
        const tmp = sprite.writeSpriteData();
        this.container.addChild(tmp);

        this._checkRenderTex(spriteBounds.width, spriteBounds.height);
        this.renderTex.frame = new PIXI.Rectangle(0, 0, spriteBounds.width, spriteBounds.height);
        this.renderTex.updateUvs();

        this.container.x = -spriteBounds.x;
        this.container.y = -spriteBounds.y;

        this.renderer.render(this.container, this.renderTex);

        this.container.removeChildren();

        const pixels = this.renderer.extract.pixels(this.renderTex);

        const width = Math.floor(spriteBounds.width);
        const height = Math.floor(spriteBounds.height);

        if (stageBounds.right < spriteBounds.right) {
            const diff = Math.floor(spriteBounds.right - stageBounds.right);
            if (this._checkField(pixels, width, width - diff, width, 0, height) && AppRoot.INSTANCE.state.playing) {
                if (!sprite.cachedTouchEvaluations.edge) {
                    sprite.cachedTouchEvaluations.edge = true;
                    Sprite.eventEmitter.emit("touched", sprite, "edge");
                }
                return true;
            }
        }

        if (stageBounds.left > spriteBounds.left) {
            const diff = Math.floor(stageBounds.left - spriteBounds.left);
            if (this._checkField(pixels, width, 0, diff, 0, height) && AppRoot.INSTANCE.state.playing) {
                if (!sprite.cachedTouchEvaluations.edge) {
                    sprite.cachedTouchEvaluations.edge = true;
                    Sprite.eventEmitter.emit("touched", sprite, "edge");
                }
                return true;
            }
        }

        if (stageBounds.bottom < spriteBounds.bottom) {
            const diff = Math.floor(spriteBounds.bottom - stageBounds.bottom);
            if (this._checkField(pixels, width, 0, width, height - diff, height) && AppRoot.INSTANCE.state.playing) {
                if (!sprite.cachedTouchEvaluations.edge) {
                    sprite.cachedTouchEvaluations.edge = true;
                    Sprite.eventEmitter.emit("touched", sprite, "edge");
                }
                return true;
            }
        }

        if (stageBounds.top > spriteBounds.top) {
            const diff = Math.floor(stageBounds.top - spriteBounds.top);
            if (this._checkField(pixels, width, 0, width, 0, diff) && AppRoot.INSTANCE.state.playing) {
                if (!sprite.cachedTouchEvaluations.edge) {
                    sprite.cachedTouchEvaluations.edge = true;
                    Sprite.eventEmitter.emit("touched", sprite, "edge");
                }
                return true;
            }
        }

        sprite.cachedTouchEvaluations.edge = false;
        return false;
    }

    /**
     * Checks if there are non-alpha values in the given area.
     *
     * @param {Uint8ClampedArray} pixels Pixel data
     * @param {number} width Width of the area to be checked
     * @param {number} xStart x-start of the area to be checked
     * @param {number} xEnd x-end of the area to be checked
     * @param {number} yStart y-start of the area to be checked
     * @param {number} yEnd y-end of the area to be checked
     * @returns {boolean}
     * @private
     */
    _checkField(pixels: Uint8ClampedArray | Uint8Array,
        width: number, xStart: number, xEnd: number, yStart: number, yEnd: number) {
        for (let y = yStart; y < yEnd; y++) {
            for (let x = xStart; x < xEnd; x++) {
                if (pixels[(y * width + x) * 4 + 3] > 5) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Checks if the given sprites touch each other.
     *
     * A pixel-perfect collision check is performed, if the bounding boxes of the sprites intersect.
     *
     * @param {Sprite} sprite Sprite to be checked
     * @param {number} otherSpriteId Other sprite ID
     * @returns {boolean}
     * @public
     */
    isTouchingSprite(sprite: Sprite, otherSpriteId: number) {
        if (sprite.touchingSpriteBLOCKED !== 0 && performance.now() - sprite.touchingSpriteBLOCKED <= 125) {
            //#TODO: Currently only evaluates on parent sprite, not on its clones!
            return this.world.sprites[otherSpriteId].cachedTouchEvaluations.sprite;
        }

        let otherSprites = this.world.sprites[otherSpriteId].getInstances().filter((spr) => this._spritesIntersect(sprite, spr));
        if (otherSprites.length === 0) {
            sprite.cachedTouchEvaluations.sprite = false;
            return false;
        }
        if (!sprite.hasNewPosition() && otherSprites.every(s => !s.hasNewPosition())) {
            return sprite.cachedTouchEvaluations.sprite;
        };

        const pixels = this._getCollisionPixels(sprite, otherSprites);

        for (let i = 0; i < pixels.length; i += 4) {
            const alpha = pixels[i + 3];

            if (alpha > 5 && AppRoot.INSTANCE.state.playing) {
                if (!sprite.cachedTouchEvaluations.sprite) {
                    sprite.cachedTouchEvaluations.sprite = true;
                    sprite.touchingSpriteBLOCKED = performance.now();
                    Sprite.eventEmitter.emit("touched", sprite, otherSpriteId);
                }        
                return true;
            }
        }
        sprite.cachedTouchEvaluations.sprite = false;
        return false;
    }

    /**
     * Checks if the bounding boxes of the given sprites intersect.
     *
     * @param {Sprite} sa Sprite a
     * @param {Sprite} sb Sprite b
     * @returns {boolean}
     * @private
     */
    _spritesIntersect(sa: Sprite, sb: Sprite) {
        const ab = this.getBounds(sa);
        const bb = this.getBounds(sb);
        return RenderUtils.rectIntersect(ab, bb);
    }

    /**
     * Checks if the given bounding boxes intersect.
     *
     * @param {PIXI.Rectangle} ab Bounding box a
     * @param {PIXI.Rectangle} bb Bounding box b
     * @returns {boolean}
     */
    static rectIntersect(ab: PIXI.Rectangle, bb: PIXI.Rectangle) {
        return ab.x + ab.width > bb.x
            && ab.x < bb.x + bb.width
            && ab.y + ab.height > bb.y
            && ab.y < bb.y + bb.height;
    }

    /**
     * The other sprites are rendered to a temporary texture.
     * The given sprite is used as a mask so that only the colliding pixels are rendered.
     * The pixels of the resulting texture are returned.
     *
     * @param {Sprite} sprite Sprite to be checked
     * @param {Sprite[]} otherSprites Other Sprites
     * @param {number} colorMask Color-mask being applied on the sprite
     * @returns {Uint8ClampedArray} Pixel data of the collision area
     * @private
     */
    _getCollisionPixels(sprite: Sprite, otherSprites: Sprite[], colorMask?: number) {
        const mask = new PIXI.Sprite(sprite.pixiSprite.texture);

        if (colorMask !== undefined) {
            mask.filters = [this.silhouetteColorMaskFilter];
            this.silhouetteColorMaskFilter.uniforms.uColorMask = PIXI.utils.hex2rgb(colorMask);
        } else {
            mask.filters = [this.silhouetteFilter];
        }

        // TODO: remove tmpTex
        const tmpTex = PIXI.RenderTexture.create({ width: sprite.pixiSprite.texture.width, height: sprite.pixiSprite.texture.height });
        this.renderer.render(mask, tmpTex);

        mask.texture = tmpTex;
        //@ts-ignore
        mask.filters = null;
        sprite.writeSpriteData(mask, true);

        this.container.addChild(mask);
        this.container.mask = mask;

        let otherPixiSprites = otherSprites.map((spr) => spr.writeSpriteData());
        this.container.addChild.apply(this.container, otherPixiSprites);

        const bounds = this.getBounds(sprite);

        this._checkRenderTex(bounds.width, bounds.height);
        this.renderTex.frame = new PIXI.Rectangle(0, 0, bounds.width, bounds.height);
        this.renderTex.updateUvs();

        this.container.x = -bounds.x;
        this.container.y = -bounds.y;

        this.renderer.render(this.container, this.renderTex);

        this.container.removeChildren();

        //@ts-ignore
        this.container.mask = null;

        // @ts-ignore
        mask.destroy(true);
        tmpTex.destroy(true);

        return this.renderer.extract.pixels(this.renderTex);
    }

    /**
     * Calculates the bounding box of the given sprite.
     *
     * @param {Sprite} sprite sprite whose bounding box is needed
     * @returns {PIXI.Rectangle} Bounding box
     */
    getBounds(sprite: Sprite) {
        // TODO: calculate this instead of using the global bounds and scaling them
        const scale = 1 / this.world.scale;
        const bounds = sprite.pixiSprite.getBounds(sprite.lastPosition && !sprite.hasNewPosition());
        const costume = sprite.costumes.find((c) => c.id === sprite.costumeId);
        if (costume && costume.offset.x > -1) {
            const offset = {
                x: Math.round(costume.offset.x / (sprite.pixiSprite.texture.width / bounds.width)),
                y: Math.round(costume.offset.y / (sprite.pixiSprite.texture.height / bounds.height))
            };
            bounds.x += offset.x;
            bounds.y += offset.y;
            bounds.width -= 2 * offset.x;
            bounds.height -= 2 * offset.y;
        }
        bounds.x *= scale;
        bounds.y *= scale;
        bounds.width *= scale;
        bounds.height *= scale;

        bounds.y -= FlatWorld.worldSize().height;

        if (this.world.centerAsOrigin) {
            bounds.x -= FlatWorld.worldSize().width / 2;
            bounds.y += FlatWorld.worldSize().height / 2;
        }

        return bounds;
    }

    /**
     * Gets the posisiton of the mouse
     * @return mouse position
     */

    getMousePos() {
        let mousePos = this.renderer.plugins.interaction.mouse.getLocalPosition(this.world.container);
        return mousePos;
    }

    setResolution(resolution: number) {
        this.renderer.resolution = resolution;
        this.silhouetteFilter.resolution = resolution;
        this.silhouetteColorMaskFilter.resolution = resolution;
        this.renderer.plugins.interaction.resolution = resolution;
        if (this.world.scale > 1) {
            this.renderer.resize(FlatWorld.worldSize().width * this.world.scale, FlatWorld.worldSize().height * this.world.scale);
        } else {
            this.renderer.resize(FlatWorld.worldSize().width, FlatWorld.worldSize().height * this.world.scale);
        }
    }

    static rgbToHex = (r: number, g: number, b: number) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('');

    static rgbToHsl = (r: number, g: number, b: number) => {
        let _R = (r / 255);
        let _G = (g / 255);
        let _B = (b / 255);

        let _Min = Math.min(_R, _G, _B);
        let _Max = Math.max(_R, _G, _B);
        let del_Max = _Max - _Min;

        let l = (_Max + _Min) / 2;
        let h = 0;
        let s = 0;

        if (del_Max !== 0) {
            if (l < 0.5) {
                s = del_Max / (_Max + _Min);
            } else {
                s = del_Max / (2 - _Max - _Min);
            }

            let del_R = (((_Max - _R) / 6) + (del_Max / 2)) / del_Max;
            let del_G = (((_Max - _G) / 6) + (del_Max / 2)) / del_Max;
            let del_B = (((_Max - _B) / 6) + (del_Max / 2)) / del_Max;

            if (_R === _Max) {
                h = del_B - del_G;
            } else if (_G === _Max) {
                h = (1 / 3) + del_R - del_B;
            } else if (_B === _Max) {
                h = (2 / 3) + del_G - del_R;
            }

            if (h < 0) {
                h += 1;
            }
            if (h > 1) {
                h -= 1;
            }
        }
        return [h, s, l];
    };

    static rgbToXyz = (r: number, g: number, b: number) => {
        let _R = (r / 255);
        let _G = (g / 255);
        let _B = (b / 255);

        if (_R > 0.04045) {
            _R = ((_R + 0.055) / 1.055) ** 2.4;
        } else {
            _R /= 12.92;
        }
        if (_G > 0.04045) {
            _G = ((_G + 0.055) / 1.055) ** 2.4;
        } else {
            _G /= 12.92;
        }
        if (_B > 0.04045) {
            _B = ((_B + 0.055) / 1.055) ** 2.4;
        } else {
            _B /= 12.92;
        }

        _R *= 100;
        _G *= 100;
        _B *= 100;

        let x = _R * 0.4124 + _G * 0.3576 + _B * 0.1805;
        let y = _R * 0.2126 + _G * 0.7152 + _B * 0.0722;
        let z = _R * 0.0193 + _G * 0.1192 + _B * 0.9505;
        return [x, y, z];
    };

    static xyzToLab = (x: number, y: number, z: number) => {
        let _X = x / 96.720;
        let _Y = y / 100.000;
        let _Z = z / 81.427;

        if (_X > 0.008856) {
            _X = _X ** (1 / 3);
        } else {
            _X = (7.787 * _X) + (16 / 116);
        }
        if (_Y > 0.008856) {
            _Y = _Y ** (1 / 3);
        } else {
            _Y = (7.787 * _Y) + (16 / 116);
        }
        if (_Z > 0.008856) {
            _Z = _Z ** (1 / 3);
        } else {
            _Z = (7.787 * _Z) + (16 / 116);
        }

        let l = (116 * _Y) - 16;
        let a = 500 * (_X - _Y);
        let b = 200 * (_Y - _Z);
        return [l, a, b];
    };

    static deltaE94 = (lab1: number[], lab2: number[]) => {
        let WHT_L = 1, WHT_C = 1, WHT_H = 1; //Weighting factors

        let xC1 = Math.sqrt((lab1[1] ** 2) + (lab1[2] ** 2));
        let xC2 = Math.sqrt((lab2[1] ** 2) + (lab2[2] ** 2));
        let xDL = lab2[0] - lab1[0];
        let xDC = xC2 - xC1;
        let xDE = Math.sqrt(((lab1[0] - lab2[0]) * (lab1[0] - lab2[0]))
            + ((lab1[1] - lab2[1]) * (lab1[1] - lab2[1]))
            + ((lab1[2] - lab2[2]) * (lab1[2] - lab2[2])));

        let xDH = (xDE * xDE) - (xDL * xDL) - (xDC * xDC);
        if (xDH > 0) {
            xDH = Math.sqrt(xDH);
        } else {
            xDH = 0;
        }
        let xSC = 1 + (0.045 * xC1);
        let xSH = 1 + (0.015 * xC1);
        xDL /= WHT_L;
        xDC /= WHT_C * xSC;
        xDH /= WHT_H * xSH;

        return Math.sqrt(xDL ** 2 + xDC ** 2 + xDH ** 2);
    };
};

import { Interpreter } from './lib/interpreter/interpreter';

import * as acorn from 'acorn';
import { Logging } from "./utils/logging";

export interface PropertyItem {
    name: string;
    value: any;
    descriptor?: PropertyDescriptor;
}

interface ThreadData {
    stateStack: any[];
    paused: boolean;
}

export class JSProgram {
    static ReadonlyDescriptor: PropertyDescriptor = {writable: false, configurable: true};

    ownerId: number;
    spriteGuid: string;
    idleThread: ThreadData;
    interpreter: any;
    programAst: acorn.Node;

    constructor(programAst: acorn.Node, ownerId: number, spriteId: string, globalProps?: PropertyItem[]) {
        this.programAst = JSON.parse(JSON.stringify(programAst));
        this.interpreter = new Interpreter(programAst);
        this.idleThread = this.interpreter.thread;
        this.ownerId = ownerId;
        this.spriteGuid = spriteId;

        if (globalProps) {
            globalProps.forEach((prop) => this.interpreter.setProperty(this.interpreter.global, prop.name, prop.value, prop.descriptor));
        }
    }

    createPrimitiveProp(name: string, obj: any, descriptor?: PropertyDescriptor) {
        if (descriptor) {
            if (descriptor.value || descriptor.get || descriptor.set) {
                obj = Interpreter.VALUE_IN_DESCRIPTOR;
            }
            if (descriptor.get) {
                descriptor.get = this.interpreter.createNativeFunction(descriptor.get);
            }
            if (descriptor.set) {
                descriptor.set = this.interpreter.createNativeFunction(descriptor.set);
            }
        }
        return {name: name, value: obj, descriptor: descriptor} as PropertyItem;
    }

    createNonPrimitiveProp(name: string, obj: {}, descriptor?: { configurable?: boolean, enumerable?: boolean, writable?: boolean }) {
        return {name: name, value: this.createNonPrimitive(obj, descriptor), descriptor: descriptor} as PropertyItem;
    }

    createFunctionProp(name: string, fn: Function, descriptor?: { configurable?: boolean, enumerable?: boolean, writable?: boolean }) {
        return {name: name, value: this.createFunction(name, fn), descriptor: descriptor} as PropertyItem;
    }

    registerGlobalProps(props: PropertyItem[]) {
        props.forEach((prop) => this.interpreter.setProperty(this.interpreter.global, prop.name, prop.value, prop.descriptor));
    }

    unregisterGlobalProps(props: PropertyItem[]) {
        props.forEach((prop) => this.interpreter.setProperty(this.interpreter.global, prop.name, undefined));
    }

    private handleProp(pseudoObj: any, parentObject: {}, key: string, childObject: any, descriptor?: PropertyDescriptor) {
        let obj = null;
        if (typeof childObject === "function") {
            obj = this.createFunction(key, childObject.bind(parentObject));
        } else if (typeof childObject === "object") {
            obj = this.createNonPrimitive(childObject);
        }
        if (obj) {
            this.interpreter.setProperty(pseudoObj, key, obj, descriptor);
        }
    }

    private createNonPrimitive(parentObject: {}, descriptor?: PropertyDescriptor) {
        // Does not resolve recursion on objects.
        let pseudoObj = this.interpreter.createObjectProto(this.interpreter.OBJECT_PROTO);
        for (let key in parentObject) {
            if (parentObject.hasOwnProperty(key) && !key.startsWith("_")) {
                this.handleProp(pseudoObj, parentObject, key, parentObject[key], descriptor);
            }
        }
        const proto = Object.getPrototypeOf(parentObject);
        Object.getOwnPropertyNames(proto).forEach((key) => {
            if (!key.startsWith("_") && key !== "constructor") {
                this.handleProp(pseudoObj, parentObject, key, proto[key], descriptor);
            }
        });
        return pseudoObj;
    }

    private createFunction(name: string, fn: Function) {
        return name.endsWith('Async')
            ? this.interpreter.createAsyncFunction(fn)
            : this.interpreter.createNativeFunction(fn);
    }
}

enum ThreadState {
    Running,
    Yield,
    Blocked,
    Done
}

export interface Runtime {
    exit: () => void;
    yield: () => void;
    threadId: () => number;
    runtime: () => Runtime;
    data: any;
}

export class Thread {
    id: number;
    state: ThreadState;
    stepCounter: number;
    stepInterval: number;
    stepExecution: boolean;
    interval: number;
    endCallback?: () => void;
    private program: JSProgram;
    private threadData: ThreadData;
    private threadProps: PropertyItem[];
    private lastRun: number;

    constructor(id: number, program: JSProgram, threadProps: PropertyItem[], endCallback?: () => void) {
        this.id = id;
        this.program = program;
        this.state = ThreadState.Running;
        this.stepCounter = 0;
        this.stepInterval = 1;
        this.stepExecution = true;
        this.interval = 0;
        this.threadData = program.interpreter.createThread();
        this.threadProps = threadProps;
        this.endCallback = endCallback;
        this.lastRun = Number.MIN_SAFE_INTEGER;

        this.threadProps.push(program.createNonPrimitiveProp("runtime", {
            exit: () => this.state = ThreadState.Done,
            yield: () => this.state = ThreadState.Yield,
            threadId: () => this.id,
            runtime: function () {
                return this;
            },
            data: {}
        } as Runtime));
    }

    run() {
        this.stepCounter++;
        if (this.stepCounter % this.stepInterval !== 0) return;
        let resume = this.state === ThreadState.Blocked && !this.threadData.paused;
        if (resume) {
            this.state = ThreadState.Running;
        }

        if (this.state !== ThreadState.Running) {
            return;
        }

        let prg = this.program;
        prg.interpreter.thread = this.threadData;
        prg.registerGlobalProps(this.threadProps);

        if (!this.stepExecution) {
            const now = Date.now();
            if (this.lastRun + this.interval > now) return;
            this.lastRun = now;
            prg.interpreter.run();
            prg.interpreter.appendCode(prg.programAst);
            prg.unregisterGlobalProps(this.threadProps);
            return;
        }
        while (this.state === ThreadState.Running) {
            try {
                if (!prg.interpreter.step()) {
                    this.state = ThreadState.Done;
                }
                if (this.threadData.paused) {
                    this.state = ThreadState.Blocked;
                }
                if (this.state as ThreadState === ThreadState.Yield) {
                    this.state = ThreadState.Running;
                    if (!resume) {
                        break;
                    }
                    resume = false;
                }
            } catch (e) {
                this.state = ThreadState.Done;
                Logging.error("Execution failed." + e.toString());
                prg.interpreter.thread = prg.idleThread;
            }
        }

        prg.unregisterGlobalProps(this.threadProps);
    }

    getOwnerId() {
        return this.program.ownerId;
    }

    getSpriteGuid() {
        return this.program.spriteGuid;
    }

    toString(): string {
        return `Thread[${this.id}]`;
    }
}

/**
 * CodeRunner provides runs javascript-code in a save fashion with graceful shutdown, stepping,
 * white-list interfaces and asynchronous callbacks.
 * <b>See cheatsheet.md</b>
 *
 * @param {Object.<string, object>} externals A dictionary of external objects/functions, which are mapped
 * to the global scope of the sandbox by their key.
 *
 * @property {Object.<string, object>} externalInterfaces A dictionary of external objects/functions, which are
 * mapped to the global scope of the sandbox by their key.
 * @property {bool} running A flag indicicating current execution.
 *
 * @constructor
 *
 * @requires https://github.com/NeilFraser/JS-Interpreter
 * @todo Implement public functions for stepping.
 * @todo Check if thread has died (with error). [Using a pingback]
 */
export default class CodeRunner {
    private threads: Thread[];
    private startingThreads: Thread[];
    private stopFlag: boolean;
    private newFrameFlag: boolean;
    private stepTime: number;
    private startTime: number;
    private fastMode: boolean;
    private threadCounter: number;
    private pauseFlag: boolean;

    constructor() {
        this.threads = [];
        this.startingThreads = [];
        this.stopFlag = false;
        this.pauseFlag = false;
        this.threadCounter = 0;
        this.startTime = Date.now();
        this.newFrameFlag = false;

        this.stepTime = 1000 / 60;
        this.fastMode = false;
        setInterval(this.step, this.stepTime);
    }

    /**
     * Stops current execution.
     */
    stop() {
        if (this.threads.length > 0) {
            this.stopFlag = true;
        }
        this.startingThreads = [];
    }

    /**
     * Toogle pause state
     */
    togglePausePlay() {
        this.pauseFlag = !this.pauseFlag;
    }

    /**
     * Pause the interpreter.
     */
    pause() {
        this.pauseFlag = true;
    }

    isPaused() {
        return this.pauseFlag;
    }

    getStartTime() {
        return this.startTime;
    }

    setStartTime() {
        this.startTime = Date.now();
    }

    /**
     * Unpause interpreter.
     */
    unpause() {
        this.pauseFlag = false;
    }

    toggleFastMode() {
        this.fastMode = !this.fastMode;
    }

    newFrame() {
        this.newFrameFlag = true;
    }

    /**
     * Runs a piece of code.
     *
     * @param program
     */
    runThread(program: JSProgram, globalProps: PropertyItem[], endCallback?: () => void) : Thread {
        const threadId = this.threadCounter;
        var thread = new Thread(threadId, program, globalProps, endCallback);
        this.startingThreads.push(thread);
        this.threadCounter++;
        return thread;
    }

    stopAll() {
        this.threads.forEach((thread) => {
            thread.state = ThreadState.Done;
        });
    }

    stopByOwner(owner: number, exceptions: number[] = []) {
        this.threads.forEach((thread) => {
            if (thread.getOwnerId() === owner && exceptions.indexOf(thread.id) === -1) {
                thread.state = ThreadState.Done;
            }
        });
    }

    stopBySpriteGuid(spriteGuid: string) {
        this.threads.forEach((thread) => {
            if (thread.getSpriteGuid() === spriteGuid) {
                thread.state = ThreadState.Done;
            }
        });
    }

    stopById(ids: number[]) {
        this.threads.forEach((thread) => {
            if (ids.indexOf(thread.id) !== -1) {
                thread.state = ThreadState.Done;
            }
        });
    }

    isRunning(ids: number[]) {
        return ids.filter((id) => this.threads.find((t) => t.id === id));
    }

    threadCount() {
        return this.threads.length;
    }

    private step = () => {
        if (this.pauseFlag) {
            return;
        }

        if (this.stopFlag) {
            this.stopFlag = false;

            for (let i = 0; i < this.threads.length; i++) {
                this.threads[i].state = ThreadState.Done;
            }
        }

        this.threads = this.threads.concat(this.startingThreads);
        this.startingThreads = [];

        let activeThreads = 1;
        const startTime = Date.now();

        this.newFrameFlag = false;

        while (this.threads.length > 0 && activeThreads > 0 && Date.now() - startTime < this.stepTime / 2
        && (!this.newFrameFlag || this.fastMode)) {
            activeThreads = 0;
            for (let i = 0; i < this.threads.length; i++) {
                this.threads[i].run();
                if (this.threads[i].state === ThreadState.Running) {
                    activeThreads++;
                }
            }
        }

        const endCBs = this.threads.map((t) => t.state === ThreadState.Done && t.endCallback);
        this.threads = this.threads.filter(thread => thread.state !== ThreadState.Done);
        endCBs.forEach((cb) => cb && cb());
    };
}
